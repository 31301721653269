import React from 'react';
import './ToolComponent.css';
import dmimg from '../../../assets/dm-500-500.png';

const Pricing = ({ rows, cost, title }) => {
    return (
        <div className="info-block">
            {title && <h3 className='infoBlockTitle'>{title}</h3>}
            <span>{cost} <img className='infoBlockCurrency' src={dmimg} alt="Currency" /> per {rows} row(s)</span>
        </div>
    );
};

export default Pricing;

import React, { useEffect } from 'react';
import './FCPayment.css'; // Import the CSS file

const FCPayment = () => {
    useEffect(() => {
        // Function to load the Stripe Pricing Table script
        const loadStripePricingTable = () => {
            if (!document.querySelector('#stripe-pricing-table-script')) {
                const script = document.createElement('script');
                script.id = 'stripe-pricing-table-script';
                script.src = 'https://js.stripe.com/v3/pricing-table.js';
                script.async = true;
                document.body.appendChild(script);
            }
        };

        loadStripePricingTable();
    }, []);

    return (
        <div className="payment-container">
            <h1 className="payment-title">FC 25 Auto Trader - Pricing Plans</h1>
            <stripe-pricing-table 
                pricing-table-id="prctbl_1Q4Z4jGBQeIDfgAMjjQBLsGB"
                publishable-key="pk_live_51PAlIQGBQeIDfgAMYKLSoWlpaUPvTttVx99sRzEJp5wysupMuwm8AHLl0M3Y14lHq1isBvYXI2SIOSkgebX7Fxpa00jvLHrtCW">
            </stripe-pricing-table>
            <p className="payment-info">
                Please refresh your Ultimate Team Web App upon payment confirmation. It may take up to 5 minutes for the subscription to become active, unless there is a deeper issue involving the payment such as fraud.
            </p>
        </div>
    );
};

export default FCPayment;

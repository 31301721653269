import React from 'react';
import './Dashboard.css';
import RecentRuns from '../components/RecentRuns';

const Dashboard = () => {
    return (
        <div className="dashboard-container">
            <h1>Welcome to your dashboard</h1>
            <RecentRuns />
        </div>
    );
};

export default Dashboard;

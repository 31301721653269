import React, { useState } from 'react';
import './FreeForm.css';
import Loading from '../components/Loading';

const MakeCSV = () => {
    const [inputText, setInputText] = useState('');
    const [outputText, setOutputText] = useState('');
    const [csvState, setCSVState] = useState('initial');

    const handleInput = (event) => {
        setInputText(event.target.value);
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        setCSVState('initial-loading');
        var output = inputText.replace(/\n/g, '","');
        output = `"${output}"`;

        output = output.replace(/","/g, 'MIDPART').replace(/"/g, "'").replace(/MIDPART/g, '","').replace(/^'|'$/g, "").replace(/  +|\t/g, ' ').trim().replace(/"," | "," | ","/g, '","');
        setOutputText(output);
        setCSVState('initial-jobdone');
    }

    const handleDownload = (data, filename = 'download.txt', mimeType = 'text/csv') => {
        var blob = new Blob([data], { type: mimeType });
        var url = URL.createObjectURL(blob);
        var downloadLink = document.createElement("a");
        downloadLink.download = filename;
        downloadLink.href = url;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        URL.revokeObjectURL(url);
        downloadLink.remove();
    };

    return (
        <section className='dm-free-tool-section'>
            {csvState.includes('loading') && (
                <Loading />
            )}
            <form className='dm-free-tool-form' onSubmit={handleSubmit}>
                <textarea
                    className='dm-free-tool-input'
                    value={inputText}
                    onChange={handleInput}
                    placeholder='Input text 1 per line'
                    cols="50"
                    rows="10"
                />
                <button className='dm-free-tool-button' type='submit'>Generate CSV</button>
            </form>
            {csvState.includes('jobdone') && (
                <div className='dm-free-tool-section'>
                    <br />
                    <button className='dm-free-tool-button' onClick={() => handleDownload(outputText, 'Data Menu - Make CSV')}>Download Results</button>
                </div>
            )}
        </section>
    )
};

export default MakeCSV;

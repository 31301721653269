import React from 'react';
import GridItem from './GridItem';

const GridSection = ({ jobTitle, employees, colors }) => {
  return (
    <div className="grid-section">
      <div className="grid-title" style={{ background: colors.primary }}>{jobTitle}</div>
      <div className="grid-container">
        {employees.map(employee => (
          <GridItem key={employee.employee} employee={employee} colors={colors} />
        ))}
      </div>
    </div>
  );
};

export default GridSection;

import React from 'react';
import { Link } from 'react-router-dom';
import './ToolItem.css';

const Tool = ({ name, image, path }) => {
    return (
        <Link to={path} className='tool-item'>
            <img src={image} alt={name} className='tool-image' />
            <div className='tool-name'>{name}</div>
        </Link>
    );
};

export default Tool;

import React from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import InvoiceCheckoutForm from './InvoiceCheckoutForm'; // New CheckoutForm for invoices
import './StripeInvoiceContainer.css';

const stripePromise = loadStripe('pk_live_51PAlIQGBQeIDfgAMYKLSoWlpaUPvTttVx99sRzEJp5wysupMuwm8AHLl0M3Y14lHq1isBvYXI2SIOSkgebX7Fxpa00jvLHrtCW');

const StripeInvoiceContainer = ({ invoiceId, amount }) => {
    return (
        <Elements stripe={stripePromise}>
            <InvoiceCheckoutForm invoiceId={invoiceId} amount={amount} />
        </Elements>
    );
};

export default StripeInvoiceContainer;

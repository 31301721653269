import React from 'react';
import './Home.css';
import InteractiveBanner from '../components/InteractiveBanner';
import FeatureBlock from '../components/FeatureBlock';
import PricingCard from '../components/PricingCard';
import TestimonialCarousel from '../components/TestimonialCarousel';
import CTASection from '../components/CTASection';

const Home = () => {
    const testimonials = [
        {
            quote: "Data Menu has transformed our business! The analytics tools are incredible.",
            name: "John Doe",
            position: "CEO, ExampleCorp"
        },
        {
            quote: "The data management features are top-notch. Highly recommend Data Menu!",
            name: "Jane Smith",
            position: "CTO, TechSolutions"
        },
        {
            quote: "Fantastic service with excellent uptime. We couldn't be happier.",
            name: "Emily Johnson",
            position: "COO, BusinessWorks"
        },
        {
            quote: "Our data operations have never been smoother. Data Menu is a game changer.",
            name: "Michael Brown",
            position: "Data Manager, InfoTech"
        },
        {
            quote: "Reliable, efficient, and user-friendly. Data Menu is our go-to solution.",
            name: "Lisa White",
            position: "Operations Head, FinCorp"
        }
    ];

    return (
        <div className="home-container">
            <header className="home-header">
                <InteractiveBanner />
                <div className="welcome">
                    <h1>Welcome to Data Menu</h1>
                    <p>Your ultimate data management platform.</p>
                </div>
            </header>
            <section className="features-section">
                <h2>Our Key Features</h2>
                <div className="features-grid">
                    <FeatureBlock
                        icon="fa-database"
                        title="Data Management"
                        body="Efficiently manage and organize your data with our robust platform."
                    />
                    <FeatureBlock
                        icon="fa-chart-line"
                        title="Analytics"
                        body="Gain valuable insights with our comprehensive data analytics tools."
                    />
                    <FeatureBlock
                        icon="fa-shield-alt"
                        title="Security"
                        body="Keep your data safe with our top-notch security measures."
                    />
                    <FeatureBlock
                        icon="fa-cloud-upload-alt"
                        title="Cloud Backup"
                        body="Nothing hosted locally, access your data from any device."
                    />
                    <FeatureBlock
                        icon="fa-check-circle"
                        title="Accurate Data"
                        body="Get your data from over 100+ streamlined sources."
                    />
                    <FeatureBlock
                        icon="fa-sync-alt"
                        title="99.99% Up-time"
                        body="Enjoy your service with a stellar 99.99% up-time!"
                    />
                </div>
            </section>
            <section className="testimonials-section">
                <h2>What Our Customers Say</h2>
                <TestimonialCarousel/>
            </section>
            <section className="pricing-section">
                <h2>Pricing Plans</h2>
                <div className="pricing-grid">
                    <PricingCard
                        plan="Basic"
                        price="£5/week"
                        features={["10% Bonus Credits","500 Credits/week"]}
                        buttonText="Get Started"
                        buttonAction="/pricing"
                    />
                    <PricingCard
                        plan="Standard"
                        price="£10/week"
                        features={["20% Bonus Credits","1250 Credits/week"]}
                        buttonText="Get Started"
                        buttonAction="/pricing"
                    />
                    <PricingCard
                        plan="Premium"
                        price="£25/week"
                        features={["40% Bonus Credits","4000 Credits/week"]}
                        buttonText="Get Started"
                        buttonAction="/pricing"
                    />
                    <PricingCard
                        plan="Enterprise"
                        price="Contact Us"
                        features={["Custom Solutions", "Dedicated Support"]}
                        buttonText="Contact Us"
                        buttonAction="https://www.datamenu.co.uk/contact-us"
                        isExternal={true}
                    />
                </div>
            </section>
            <CTASection />
        </div>
    );
};

export default Home;

import React, { useState, useEffect, useRef } from 'react';
import GridSection from './components/GridSection';
import './ConradBrothers.css';

const ConradBrothers = () => {
  const [employees, setEmployees] = useState({});
  const [totalEmployees, setTotalEmployees] = useState(0);
  const [scrollTime, setScrollTime] = useState(0);
  const usedColors = useRef(new Set());
  const refreshInterval = 20; // Refresh interval in minutes

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch('https://clock-shark.s3.eu-north-1.amazonaws.com/external.json');
      const result = await response.json();
      updateEmployees(result);
      calculateScrollTime(result);
    };

    fetchData();
    const interval = setInterval(fetchData, refreshInterval * 60 * 1000); // Refresh data every 'refreshInterval' minutes

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (Object.keys(employees).length > 0) {
      applyCreditScrollClass();
    }
  }, [employees, scrollTime]);

  const calculateScrollTime = (employeesData) => {
    let employeeCount = 0;
    Object.keys(employeesData).forEach(job => {
      employeeCount += employeesData[job].EMPLOYEES.length;
    });
    setTotalEmployees(employeeCount);
    setScrollTime(employeeCount * 3);
  };

  const applyCreditScrollClass = () => {
    const scrollSection = document.getElementById('scroll-section');
    if (scrollSection.scrollHeight > window.innerHeight) {
      scrollSection.classList.add('credit-scroll');
      scrollSection.style.animation = `scroll-loop ${scrollTime}s linear infinite`;
    } else {
      scrollSection.classList.remove('credit-scroll');
    }
  };

  const getRandomColor = () => {
    const primaryColors = [
      'rgb(123, 123, 240)', 'rgb(252, 177, 79)', 'rgb(165, 212, 134)',
      'rgb(134, 200, 212)', 'rgb(146, 134, 212)', 'rgb(212, 134, 208)',
      'rgb(212, 207, 134)', 'rgb(86, 150, 130)', 'rgb(134, 212, 176)',
      'rgb(170, 134, 212)', 'rgb(212, 178, 134)', 'rgb(134, 183, 212)',
      'rgb(134, 212, 189)', 'rgb(212, 134, 179)', 'rgb(134, 212, 144)',
      'rgb(134, 190, 212)', 'rgb(135, 134, 212)', 'rgb(212, 181, 134)',
      'rgb(203, 134, 212)', 'rgb(148, 134, 212)', 'rgb(212, 134, 182)'
    ];

    const secondaryColors = [
      'rgb(95, 95, 245)', 'rgb(252, 162, 45)', 'rgb(148, 223, 99)',
      'rgb(119, 206, 221)', 'rgb(128, 111, 219)', 'rgb(211, 98, 205)',
      'rgb(216, 208, 95)', 'rgb(58, 236, 183)', 'rgb(85, 228, 161)',
      'rgb(144, 73, 226)', 'rgb(209, 147, 66)', 'rgb(82, 180, 236)',
      'rgb(67, 204, 163)', 'rgb(212, 67, 152)', 'rgb(81, 223, 100)',
      'rgb(86, 180, 218)', 'rgb(84, 82, 212)', 'rgb(224, 166, 77)',
      'rgb(189, 73, 204)', 'rgb(111, 84, 231)', 'rgb(202, 65, 150)'
    ];

    let index;
    do {
      index = Math.floor(Math.random() * primaryColors.length);
    } while (usedColors.current.has(index));
    
    usedColors.current.add(index);

    return {
      primary: primaryColors[index],
      secondary: secondaryColors[index]
    };
  };

  const updateEmployees = (newData) => {
    const updatedEmployees = { ...employees };

    // Add new employees and update existing ones
    Object.keys(newData).forEach(job => {
      if (!updatedEmployees[job]) {
        updatedEmployees[job] = { EMPLOYEES: [] };
      }
      const jobEmployees = newData[job].EMPLOYEES;

      jobEmployees.forEach(newEmployee => {
        const existingEmployeeIndex = updatedEmployees[job].EMPLOYEES.findIndex(employee => employee.employee === newEmployee.employee);

        if (existingEmployeeIndex !== -1) {
          updatedEmployees[job].EMPLOYEES[existingEmployeeIndex] = newEmployee;
        } else {
          updatedEmployees[job].EMPLOYEES.push(newEmployee);
        }
      });
    });

    // Remove employees that no longer exist
    Object.keys(updatedEmployees).forEach(job => {
      updatedEmployees[job].EMPLOYEES = updatedEmployees[job].EMPLOYEES.filter(employee =>
        newData[job] && newData[job].EMPLOYEES.some(newEmployee => newEmployee.employee === employee.employee)
      );

      // Remove job if no employees are left
      if (updatedEmployees[job].EMPLOYEES.length === 0) {
        delete updatedEmployees[job];
      }
    });

    setEmployees(updatedEmployees);
  };

  const renderGrid = () => {
    const jobTitles = Object.keys(employees);
    return jobTitles.map(jobTitle => {
      const colors = getRandomColor();
      return <GridSection key={jobTitle} jobTitle={jobTitle} employees={employees[jobTitle].EMPLOYEES} colors={colors} />;
    });
  };

  return (
    <section id="conrad-brothers">
      <div id="scroll-section">
        {renderGrid()}
      </div>
    </section>
  );
};

export default ConradBrothers;

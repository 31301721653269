import React from 'react';
import dmimage from '../../../../assets/dm-500-500.png';

// Function to import all images from the images folder
function importAll(r) {
  let images = {};
  r.keys().map((item, index) => { images[item.replace('./', '')] = r(item); });
  return images;
}

const images = importAll(require.context('../images', false, /\.(png|jpe?g|svg)$/));

const GridItem = ({ employee, colors }) => {
  const workingForString = employee.workingFor;
  const isOvertime = workingForString.includes('hr') && parseInt(workingForString.split(' ')[0]) >= 8;
  
  const employeeNameTrimmed = employee.employee.trim();
  const employeeImageName = `${employeeNameTrimmed}.png`; // Assuming the images are in png format
  const employeeImage = images[employeeImageName] || dmimage; // Fallback to an empty string if the image is not found

  return (
    <div className="grid-item" style={{ borderColor: colors.primary }}>
      <div className="employeeIcon" style={{ background: colors.secondary }}>
        <img src={employeeImage} alt={employee.employee} className="employeeImg" />
      </div>
      <div className="employeeName"><strong>{employee.employee}</strong></div>
      <div className="timing">
        <div className={`clockedIn ${isOvertime ? 'overtime-secondary' : ''}`} style={{ background: colors.secondary }}>{employee.clockedIn}</div>
        <div className={`workingFor ${isOvertime ? 'overtime' : ''}`} style={{ background: colors.primary }}>{employee.workingFor}</div>
      </div>
    </div>
  );
};

export default GridItem;

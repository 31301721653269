import React from 'react';
import './Footer.css';

const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer-content">
                <p>Follow us on:</p>
                <div className="social-links">
                    <a href="https://www.facebook.com/DataMenuLimited" target='_blank' rel="noreferrer">
                        <i className="fab fa-facebook"></i>
                    </a>
                    <a href="https://instagram.com/datamenuofficial/" target='_blank' rel="noreferrer">
                        <i className="fab fa-instagram"></i>
                    </a>
                </div>
                <p>© {new Date().getFullYear()} Data Menu Limited. All rights reserved.</p>
                <div className="footer-links">
                    <a href="/privacy-policy">Privacy Policy</a> | 
                    <a href="/terms">Terms of Service</a>
                </div>
            </div>
        </footer>
    );
};

export default Footer;

import React, { useRef, useEffect } from 'react';
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';

const InteractiveBanner = () => {
    const mountRef = useRef(null);

    useEffect(() => {
        // Set up the scene, camera, and renderer
        const scene = new THREE.Scene();
        scene.background = new THREE.Color(0x000000);

        const camera = new THREE.PerspectiveCamera(75, window.innerWidth / 500, 0.1, 1000);
        camera.position.z = 20;  // Make the globe larger by increasing the camera distance

        const renderer = new THREE.WebGLRenderer({ antialias: true });
        renderer.setSize(window.innerWidth, 500);
        mountRef.current.appendChild(renderer.domElement);

        // Add lighting
        const ambientLight = new THREE.AmbientLight(0xffffff, 0.5);
        scene.add(ambientLight);
        const pointLight = new THREE.PointLight(0xffffff, 1);
        pointLight.position.set(20, 20, 20);
        scene.add(pointLight);

        // Create the globe with cubes
        const globeRadius = 10;  // Make the globe larger
        const cubeSize = 0.2;
        const globeColor = new THREE.Color(0xDCA123);
        const globe = new THREE.Group();
        for (let i = 0; i < 1000; i++) {
            const phi = Math.acos(-1 + (2 * i) / 1000);
            const theta = Math.sqrt(1000 * Math.PI) * phi;

            const x = globeRadius * Math.cos(theta) * Math.sin(phi);
            const y = globeRadius * Math.sin(theta) * Math.sin(phi);
            const z = globeRadius * Math.cos(phi);

            const geometry = new THREE.BoxGeometry(cubeSize, cubeSize, cubeSize);
            const material = new THREE.MeshStandardMaterial({ color: globeColor });
            const cube = new THREE.Mesh(geometry, material);

            cube.position.set(x, y, z);
            globe.add(cube);
        }
        scene.add(globe);

        // Create particle system
        const particleCount = 200;
        const particles = new THREE.BufferGeometry();
        const particlePositions = new Float32Array(particleCount * 3);
        const particleVelocities = [];
        const particleColors = new Float32Array(particleCount * 3);
        const particleOpacities = new Float32Array(particleCount);

        for (let i = 0; i < particleCount; i++) {
            const x = Math.random() * globeRadius - globeRadius / 2;
            const y = Math.random() * globeRadius - globeRadius / 2;
            const z = Math.random() * globeRadius - globeRadius / 2;
            particlePositions.set([x, y, z], i * 3);

            const velocity = new THREE.Vector3(
                (Math.random() - 0.5) * 0.1,
                (Math.random() - 0.5) * 0.1,
                (Math.random() - 0.5) * 0.1
            );
            particleVelocities.push(velocity);

            const color = new THREE.Color(0xDCA123);
            color.offsetHSL(Math.random() * 0.1, Math.random() * 0.1, Math.random() * 0.1);
            particleColors.set(color.toArray(), i * 3);

            particleOpacities[i] = Math.random();
        }

        particles.setAttribute('position', new THREE.BufferAttribute(particlePositions, 3));
        particles.setAttribute('color', new THREE.BufferAttribute(particleColors, 3));
        particles.setAttribute('opacity', new THREE.BufferAttribute(particleOpacities, 1));

        const particleMaterial = new THREE.PointsMaterial({
            size: 0.1,
            vertexColors: true,
            transparent: true
        });

        const particleSystem = new THREE.Points(particles, particleMaterial);
        scene.add(particleSystem);

        // Orbit controls for interaction
        const controls = new OrbitControls(camera, renderer.domElement);
        controls.enableZoom = false;
        controls.enablePan = false;

        // Animation loop
        const animate = () => {
            requestAnimationFrame(animate);
            globe.rotation.y += 0.01;  // Slow rotation

            // Update particles
            const positions = particleSystem.geometry.attributes.position.array;
            const opacities = particleSystem.geometry.attributes.opacity.array;

            for (let i = 0; i < particleCount; i++) {
                const velocity = particleVelocities[i];
                positions[i * 3] += velocity.x;
                positions[i * 3 + 1] += velocity.y;
                positions[i * 3 + 2] += velocity.z;

                opacities[i] -= 0.001;
                if (opacities[i] <= 0) {
                    positions[i * 3] = Math.random() * globeRadius - globeRadius / 2;
                    positions[i * 3 + 1] = Math.random() * globeRadius - globeRadius / 2;
                    positions[i * 3 + 2] = Math.random() * globeRadius - globeRadius / 2;
                    opacities[i] = Math.random();
                }
            }

            particleSystem.geometry.attributes.position.needsUpdate = true;
            particleSystem.geometry.attributes.opacity.needsUpdate = true;

            renderer.render(scene, camera);
        };

        animate();

        return () => {
            if (mountRef.current && renderer.domElement) {
                mountRef.current.removeChild(renderer.domElement);
            }
        };
        
    }, []);

    return <div ref={mountRef} className="interactive-banner"></div>;
};

export default InteractiveBanner;

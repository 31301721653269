import React from 'react';
import './FreeTool.css';

const FreeTool = ({ name, id, image, handleStateChange }) => {

  const imageBackground = { backgroundImage: `url(${image})` };

  return (
    <div className="source" value={id} onClick={handleStateChange}>
      <div className="image-container" datamenuimg={image} style={imageBackground}></div>
      <div className="name">{name}</div>
    </div>
  );
};

export default FreeTool;

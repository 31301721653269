import React from 'react';
import './CategoryPage.css';
import Tool from './components/ToolItem';
import bbbImage from '../../components/tool-images/bbb.png';

const Ecommerce = () => {
    const tools = [
        { name: 'BBB', image: bbbImage, path: '/bbb' }
    ];

    return (
        <section className='category-page-head'>
            <h1>Ecommerce Tools</h1>
            <section className='tool-grid'>
                {tools.map((tool, index) => (
                    <Tool key={index} name={tool.name} image={tool.image} path={tool.path} />
                ))}
            </section>
        </section>
    );
};

export default Ecommerce;

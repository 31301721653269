import React, { useState } from 'react';
import TestimonialCard from './TestimonialCard';
import './TestimonialCarousel.css';

const TestimonialCarousel = () => {
    const [visibleRows, setVisibleRows] = useState(2);

    const testimonials = [
        { quote: "Data Menu has transformed our business! The analytics tools are incredible.", name: "John Doe", position: "CEO, ExampleCorp" },
        { quote: "The data management features are top-notch. Highly recommend Data Menu!", name: "Jane Smith", position: "CTO, TechSolutions" },
        { quote: "Fantastic service with excellent uptime. We couldn't be happier.", name: "Emily Johnson", position: "COO, BusinessWorks" },
        { quote: "Our data operations have never been smoother. Data Menu is a game changer.", name: "Michael Brown", position: "Data Manager, InfoTech" },
        { quote: "Reliable, efficient, and user-friendly. Data Menu is our go-to solution.", name: "Lisa White", position: "Operations Head, FinCorp" },
        { quote: "Excellent customer support and powerful tools.", name: "David Wilson", position: "CIO, RetailCorp" },
        { quote: "Highly customizable and scalable solutions.", name: "Sara Lee", position: "Head of IT, Foodies" },
        { quote: "Great value for money and superb features.", name: "Chris Green", position: "Product Manager, ShopperStop" }
    ];

    const rowsToShow = 2;
    const testimonialsPerRow = 3;

    const handleShowMore = () => {
        setVisibleRows(visibleRows + rowsToShow);
    };

    const visibleTestimonials = testimonials.slice(0, visibleRows * testimonialsPerRow);

    return (
        <div className="testimonial-container">
            <div className="testimonial-grid">
                {visibleTestimonials.map((testimonial, index) => (
                    <TestimonialCard key={index} {...testimonial} />
                ))}
            </div>
            {visibleRows * testimonialsPerRow < testimonials.length && (
                <button className="show-more-button" onClick={handleShowMore}>Show More</button>
            )}
        </div>
    );
};

export default TestimonialCarousel;

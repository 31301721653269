import React from 'react';
import './FCPrivacy.css'; // Import the CSS file

const FCPrivacy = () => {
    return (
        <div className="privacy-container">
            <h1 className="privacy-title">Privacy Policy for FC25 Auto Trader</h1>
            <p className="privacy-intro">Effective Date: 30/09/2024</p>

            <h2>1. Introduction</h2>
            <p>
                FC25 Auto Trader ("we," "us," or "our") values your privacy. This Privacy Policy outlines how we collect, use, disclose, and safeguard your information when you use our website and services (collectively, "Services"). Please read this Privacy Policy carefully. If you do not agree with the terms of this Privacy Policy, please do not access the Services.
            </p>

            <h2>2. Information We Collect</h2>
            <p>
                We may collect information about you in a variety of ways, including:
            </p>
            <ul>
                <li>
                    <strong>Personal Data:</strong> While using our Services, you may provide us with personal information that can be used to identify you, such as your name, email address, phone number, and username.
                </li>
                <li>
                    <strong>Usage Data:</strong> We may collect information on how you access and use the Services. This data may include your IP address, browser type, operating system, the pages you visit, the time and date of your visit, and other diagnostic data.
                </li>
                <li>
                    <strong>Cookies and Tracking Technologies:</strong> We use cookies and similar tracking technologies to monitor activity on our Services and store certain information.
                </li>
            </ul>

            <h2>3. How We Use Your Information</h2>
            <p>
                We may use the information we collect for various purposes, including to:
            </p>
            <ul>
                <li>Provide and maintain our Services.</li>
                <li>Notify you about changes to our Services.</li>
                <li>Allow you to participate in interactive features of our Services when you choose to do so.</li>
                <li>Provide customer support and respond to your inquiries.</li>
                <li>Gather analysis or valuable information so that we can improve our Services.</li>
                <li>Monitor the usage of our Services.</li>
                <li>Detect, prevent, and address technical issues.</li>
                <li>Communicate with you, including to send you updates, newsletters, and marketing materials.</li>
            </ul>

            <h2>4. Disclosure of Your Information</h2>
            <p>
                We do not sell, trade, or otherwise transfer your Personal Data to outside parties without your consent, except in the following circumstances:
            </p>
            <ul>
                <li>
                    <strong>Service Providers:</strong> We may employ third-party companies and individuals to facilitate our Services, provide the Services on our behalf, perform Service-related services, or assist us in analyzing how our Services are used.
                </li>
                <li>
                    <strong>Legal Requirements:</strong> We may disclose your information if required to do so by law or in response to valid requests by public authorities (e.g., a court or a government agency).
                </li>
            </ul>

            <h2>5. Data Security</h2>
            <p>
                The security of your data is important to us, but remember that no method of transmission over the Internet or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your Personal Data, we cannot guarantee its absolute security.
            </p>

            <h2>6. Your Rights</h2>
            <p>
                Depending on your location, you may have rights to access, correct, or delete your personal information. Please contact us if you wish to exercise these rights.
            </p>

            <h2>7. Changes to This Privacy Policy</h2>
            <p>
                We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. You are advised to review this Privacy Policy periodically for any changes.
            </p>

            <h2>8. Contact Us</h2>
            <p>
                If you have any questions about this Privacy Policy, please contact us at: <br />
                corporate@datamenu.co.uk
            </p>
        </div>
    );
};

export default FCPrivacy;

import React, { useState, useEffect } from 'react';
import { useAuth } from '../../context/AuthContext';
import Loading from '../../components/Loading';
import CheckoutForm from '../../components/StripeContainer';
import './FormStyles.css';

const GoogleSearchForm = () => {
    const { isAuthenticated } = useAuth();
    const [formState, setFormState] = useState('initial');
    const [formData, setFormData] = useState({
        searchTerm: '',
        location: '',
        pageNum: 1,
        userId: '',
        type: ""
    });
    const [resultsData, setResultData] = useState({
        totalRows: 0,
        cost: 0,
        downloadData: ""
    });

    const handleDownload = (data, filename = 'download.txt', mimeType = 'text/csv') => {
        var blob = new Blob([data], { type: mimeType });
        var url = URL.createObjectURL(blob);
        var downloadLink = document.createElement("a");
        downloadLink.download = filename;
        downloadLink.href = url;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        URL.revokeObjectURL(url);
        downloadLink.remove();
    };

    useEffect(() => {
        const storedUserId = localStorage.getItem('userId');
        if (storedUserId) {
            setFormData(prevFormData => ({
                ...prevFormData,
                userId: storedUserId
            }));
        }
    }, []);

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: value
        }));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const authToken = localStorage.getItem('authToken');
        const userId = formData.userId;

        const endpoint = 'https://api.datamenu.co.uk/google-search';
        setFormState('loading');

        const response = await fetch(endpoint, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${authToken}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData)
        });

        const data = await response.json();
        var result = data.result;
        if (result === "success") {
            var totalRows = data.totalRows;
            var cost = data.cost;
            var downloadData = data.data;
            setResultData({
                totalRows,
                cost,
                downloadData
            });
            const uploadData = await fetch('https://api.datamenu.co.uk/upload-data', {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ userId: userId, query: `${formData.searchTerm} - ${formData.location} - ${formData.pageNum} - ${formData.type}`, data: downloadData, totalRows, tool: 'Google Search' })
            });

            const uploadResult = await uploadData.json();
            setFormState('download');
        } else {
            if (data.reason === 'failed to search on google') {
                setFormState('error');
            }
            if (data.reason === 'low balance') {
                setFormState('top-up');
            }
        }
    };

    return (
        <section className="dm-tool-section">
            {formState === 'initial' && (
                <div>
                    {isAuthenticated ? (
                        <form onSubmit={handleSubmit} className="dm-tool-form">
                            <h3>Google Search</h3>
                            <label className="dm-tool-label">
                                Search Term:
                                <input
                                    required
                                    type="text"
                                    name="searchTerm"
                                    value={formData.searchTerm}
                                    onChange={handleInputChange}
                                    className="dm-tool-input"
                                />
                            </label>
                            <label className="dm-tool-label">
                                Location:
                                <input
                                    required
                                    placeholder='UK/FR/US etc.'
                                    type="text"
                                    name="location"
                                    value={formData.location}
                                    onChange={handleInputChange}
                                    className="dm-tool-input"
                                />
                            </label>
                            <label className="dm-tool-label">
                                # Of Pages:
                                <input
                                    required
                                    type="number"
                                    name="pageNum"
                                    value={formData.pageNum}
                                    onChange={handleInputChange}
                                    className="dm-tool-input"
                                />
                            </label>
                            <label className="dm-tool-label">
                                Search Type:
                                <select
                                    required
                                    name="type"
                                    value={formData.type}
                                    onChange={handleInputChange}
                                    className="dm-tool-select"
                                >
                                    <option value="" disabled>Select Type</option>
                                    <option value="search">Standard Search</option>
                                    <option value="images">Images</option>
                                    <option value="places">Places</option>
                                    <option value="shopping">Shopping</option>
                                    <option value="scholar">Scholar</option>
                                </select>
                            </label>
                            <button type="submit" className="dm-tool-button">Search</button>
                        </form>
                    ) : (
                        <h3>You are not authorised to use this application</h3>
                    )}
                </div>
            )}
            {formState === 'download' && (
                <div className='download'>
                    <h3>{resultsData.totalRows} Rows Collected</h3>
                    <p>{resultsData.cost} Credits Spent</p>
                    <button onClick={() => handleDownload(resultsData.downloadData, `${formData.searchTerm}_${formData.location}_${formData.type}.csv`, 'text/csv')}>
                        Download
                    </button>
                    <div>
                        <button onClick={() => setFormState('initial')}>Collect Another Set</button>
                    </div>
                </div>
            )}
            {formState === 'error' && (
                <div>
                    <h3>Error</h3>
                    <p>There was a blip in our servers, please try again</p>
                    <div>
                        <button onClick={() => setFormState('initial')}>Collect Another Set</button>
                    </div>
                </div>
            )}
            {formState === 'top-up' && (
                <div>
                    <h3>Balance Too Low</h3>
                    <p>To proceed with a collection from Google Search, you will require at least 10 credits</p>
                    <h3>Top Up Here</h3>
                    <div>
                        <CheckoutForm />
                    </div>
                </div>
            )}
            {formState === 'top-up-failed' && (
                <div>
                    <h3>Something Went Wrong</h3>
                    <p>Please try again later, you have not been charged</p>
                    <h3>Top Up Here</h3>
                    <div>
                        <CheckoutForm />
                    </div>
                </div>
            )}
            {formState === 'loading' && (
                <div>
                    <Loading />
                </div>
            )}
        </section>
    );
};

export default GoogleSearchForm;

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import StripeInvoiceContainer from '../components/StripeInvoiceContainer';
import './UserInvoice.css';

const UserInvoice = () => {
    const { id } = useParams();
    const [invoice, setInvoice] = useState(null);

    useEffect(() => {
        const fetchInvoice = async () => {
            try {
                const response = await axios.get(`https://api.datamenu.co.uk/invoices/${id}`);
                setInvoice(response.data);
            } catch (error) {
                console.error('Error fetching invoice:', error);
            }
        };
        fetchInvoice();
    }, [id]);

    if (!invoice) {
        return <div>Loading...</div>;
    }

    return (
        <div className="user-invoice-container">
            <h1>Invoice</h1>
            <p>Email: {invoice.email}</p>
            <ul>
                {invoice.items.map((item, index) => (
                    <li key={index}>
                        {item.description} - ${item.price.toFixed(2)}
                    </li>
                ))}
            </ul>
            <p className="total">Total: ${invoice.total.toFixed(2)}</p>
            <div className="payment-container">
                <StripeInvoiceContainer invoiceId={id} amount={invoice.total} />
            </div>
        </div>
    );
};

export default UserInvoice;

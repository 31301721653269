import './App.css';
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { AuthProvider } from './context/AuthContext';
import ProtectedRoute from './components/ProtectedRoute';
import IsLoggedInRoute from './components/IsLoggedInRoute';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
import About from './pages/About';
import Services from './pages/Services';
import Pricing from './pages/Pricing';
import Login from './pages/Login';
import Register from './pages/Register';
import Dashboard from './pages/Dashboard';
import Contact from './pages/Contact';
import Terms from './pages/Terms';
import Privacy from './pages/Privacy';
import PurchaseCredits from './pages/PurchaseCredits';
import MyAccount from './pages/MyAccount';
import StripeContainer from './components/StripeContainer';
import FreeTools from './pages/FreeTools';
import PremiumTools from './pages/PremiumTools';
import DataCollection from './pages/DataCollection';
import CustomSolutions from './pages/CustomSolutions';
import AdminInvoice from './pages/AdminInvoice';
import UserInvoice from './pages/UserInvoice';
import PaymentComplete from './pages/PaymentComplete';
import TaskMeetingPage from './pages/TaskMeetingPage';

// Crypto
import TelegramMessages from './pages/TelegramMessages';

// SaaS
import BBB from './pages/tool-pages/BBB';
import GoogleSearch from './pages/tool-pages/GoogleSearch';
import YellowPagesUSA from './pages/tool-pages/YellowPagesUSA';

// Categories
import Directories from './pages/category-pages/Directories';
import Ecommerce from './pages/category-pages/Ecommerce';
import RealEstate from './pages/category-pages/RealEstate';
import SearchEngines from './pages/category-pages/SearchEngines';
import HotelsAccommodation from './pages/category-pages/HotelsAccommodation';
import Sports from './pages/category-pages/Sports';
import BusinessReviews from './pages/category-pages/BusinessReviews';

// Security
import AutomatedBrowser from './pages/AutomatedBrowser';

// Client Hosted
import ClientHostedApp from './ClientHostedApp';

(function () {
  console.log(`
  %c© 2024 Data Menu Limited. All rights reserved.
  Unauthorized copying of any files or scripts, via any medium is strictly prohibited.
  Proprietary and confidential.

  Author: Data Menu Limited
  `, 'color: green; font-size: 16px; font-weight: bold;');
})();

function App() {
  const pathname = window.location.pathname;

  if (pathname.startsWith('/conrad-brothers') || pathname.startsWith('/socials-collector') || pathname.startsWith('/moonshot') || pathname.startsWith('/fc-auto-snipe-checkout') || pathname.startsWith('/fc-privacy') ) {
    return <ClientHostedApp />;
  }

  return (
    <AuthProvider>
      <Router>
        <div className="App">
          <Header />
          <Routes>

            {/* Security Pages */}
            <Route path="/automated-browser" element={<AutomatedBrowser />} />

            {/* Core Pages */}
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/services" element={<Services />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/privacy-policy" element={<Privacy />} />
            <Route path="/pricing" element={<Pricing />} />
            <Route path="/free-tools" element={<FreeTools />} />
            <Route path="/premium-tools" element={<PremiumTools />} />
            <Route path="/data-collection" element={<DataCollection />} />
            <Route path="/custom-solutions" element={<CustomSolutions />} />
            <Route path="/admin/invoice" element={<AdminInvoice />} />
            <Route path="/invoice/:id" element={<UserInvoice />} />
            <Route path="/payment-complete" element={<PaymentComplete />} />
            <Route path="/admin/todo" element={<TaskMeetingPage />} />
            <Route path="/admin/telegram" element={<TelegramMessages />} />

            {/* Authentication Required */}
            <Route
              path="/dashboard"
              element={
                <ProtectedRoute>
                  <Dashboard />
                </ProtectedRoute>
              }
            />
            <Route
              path="/my-account"
              element={
                <ProtectedRoute>
                  <MyAccount />
                </ProtectedRoute>
              }
            />
            <Route
              path="/purchase-credits"
              component={StripeContainer}
              element={
                <ProtectedRoute>
                  <PurchaseCredits />
                </ProtectedRoute>
              }
            />
            <Route path="/register" element={<Register />} />
            <Route
              path="/login"
              element={
                <IsLoggedInRoute>
                  <Login />
                </IsLoggedInRoute>
              }
            />

            {/* Tools */}
            <Route
              path="/BBB"
              element={
                <ProtectedRoute>
                  <BBB />
                </ProtectedRoute>
              }
            />
            <Route
              path="/Google-Search"
              element={
                <ProtectedRoute>
                  <GoogleSearch />
                </ProtectedRoute>
              }
            />
            <Route
              path="/Yellow-Pages-USA"
              element={
                <ProtectedRoute>
                  <YellowPagesUSA />
                </ProtectedRoute>
              }
            />

            {/* Categories */}
            <Route path="/directories" element={<Directories />} />
            <Route path="/ecommerce" element={<Ecommerce />} />
            <Route path="/real-estate" element={<RealEstate />} />
            <Route path="/search-engines" element={<SearchEngines />} />
            <Route path="/hotels-and-accommodation" element={<HotelsAccommodation />} />
            <Route path="/sports" element={<Sports />} />
            <Route path="/business-reviews" element={<BusinessReviews />} />
          </Routes>
          <Footer />
        </div>
      </Router>
    </AuthProvider>
  );
}

export default App;

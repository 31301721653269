import React from 'react';
import './CategoryPage.css';
import Tool from './components/ToolItem';
import gsImage from '../../components/tool-images/googlesearch.png';

const SearchEngines = () => {
    const tools = [
        { name: 'Google Search', image: gsImage, path: '/Google-Search' }
    ];

    return (
        <section className='category-page-head'>
            <h1>Search Engine Tools</h1>
            <section className='tool-grid'>
                {tools.map((tool, index) => (
                    <Tool key={index} name={tool.name} image={tool.image} path={tool.path} />
                ))}
            </section>
        </section>
    );
};

export default SearchEngines;

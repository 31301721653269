import React, { useState, useEffect } from 'react';
import './Modal.css';

const Modal = ({ show, onClose, data }) => {
    if (!show) return null;

    return (
        <div className="modal-backdrop" onClick={onClose}>
            <div className="modal" onClick={(e) => e.stopPropagation()}>
                <div className="modal-content">
                    <table>
                        <thead>
                            <tr>{data[0] && data[0].map((header, index) => <th key={index}>{header}</th>)}</tr>
                        </thead>
                        <tbody>
                            {data.slice(1).map((row, idx) => (
                                <tr key={idx}>
                                    {row.map((cell, index) => <td key={index}>{cell}</td>)}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <button onClick={onClose} className="modal-close-button">Close</button>
                </div>
            </div>
        </div>
    );
};

export default Modal;

import React from 'react';
import { useLocation } from 'react-router-dom';
import './PaymentComplete.css';

const PaymentComplete = () => {
    const location = useLocation();

    const queryParams = new URLSearchParams(location.search);
    const paymentStatus = queryParams.get('status');

    return (
        <div className="payment-complete-container">
            <h1>Payment {paymentStatus === 'success' ? 'Successful' : 'Failed'}</h1>
            {paymentStatus === 'success' ? (
                <p>Thank you for your payment. Your transaction was successful.</p>
            ) : (
                <p>Unfortunately, your payment could not be processed. Please try again or contact support.</p>
            )}
        </div>
    );
};

export default PaymentComplete;

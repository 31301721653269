import React from 'react';
import './Category.css';

const Category = ({ title, icon }) => {
    return (
        <div className='category-item'>
            <span className={icon}></span>
            <div className='category-title'>
                <span>{title}</span>
            </div>
        </div>
    );
};

export default Category;

import React, { useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import {
    Elements, CardElement, useStripe, useElements,
    CardNumberElement,
    CardExpiryElement,
    CardCvcElement
} from '@stripe/react-stripe-js';
import './StripeContainer.css';
import { Link } from 'react-router-dom';
import Loading from './Loading';

const stripePromise = loadStripe('pk_test_51PAlIQGBQeIDfgAMvMpSqNrDsb08xvZdPLSHW24SFTI7NtbAQHahUb8xqKbfnqEMCEjGvkD8qbPlPQJVFADAEl1y00u6wJHswb');

const CheckoutForm = () => {
    const stripe = useStripe();
    const elements = useElements();

    const [billingDetails, setBillingDetails] = useState({
        email: '',
        name: '',
        address: {
            city: '',
            state: '',
            country: '',
            postal_code: ''
        }
    });

    const handleInputChangeAddress = (event) => {
        const { name, value } = event.target;
        if (name === "country" || name === "postal_code" || name === "city" || name === "state") {
            setBillingDetails({
                ...billingDetails,
                address: { ...billingDetails.address, [name]: value }
            });
        } else {
            setBillingDetails({ ...billingDetails, [name]: value });
        }
    };

    const [topUpState, setTopUpState] = useState('initial');

    const [paymentInitial, setPaymentInitial] = useState({
        userId: '',
        amount: 0
    });

    const [sessionToken, setSession] = useState('');

    async function presetValues(value) {
        var creditInput = document.querySelector('.amountInput');
        creditInput.value = value;
    }

    const handlePresetClick = (event) => {
        presetValues(event);
        setPaymentInitial({
            ...paymentInitial,
            amount: event
        });
    };

    const handleInputChange = (event) => {
        setPaymentInitial({
            ...paymentInitial,
            [event.target.name]: event.target.value
        });
    };

    useEffect(() => {
        const storedUserId = localStorage.getItem('userId');
        if (storedUserId) {
            setPaymentInitial(paymentInitial => ({
                ...paymentInitial,
                userId: storedUserId
            }));
        }
        const storedAuth = localStorage.getItem('authToken');
        if (storedAuth) {
            setSession(storedAuth);
        }
    }, []);

    const handleSubmit = async (event) => {
        event.preventDefault();

        setTopUpState('loading');

        if (paymentInitial.amount < 500) {
            setTopUpState('too-low');
            return;
        }

        if (!stripe || !elements) {
            return;
        }

        const cardElement = elements.getElement(CardNumberElement);
        const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: 'card',
            card: cardElement,
            billing_details: billingDetails
        });

        if (error) {
            console.log('[error]', error);
            alert('Payment failed: ' + error.message);
        } else {
            const paymentIntentResponse = await fetch('https://api.datamenu.co.uk/purchase-credits', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${sessionToken}`
                },
                body: JSON.stringify({
                    userId: paymentInitial.userId,
                    amount: paymentInitial.amount
                })
            });

            const { clientSecret } = await paymentIntentResponse.json();

            const result = await stripe.confirmCardPayment(clientSecret, {
                payment_method: paymentMethod.id,
            });

            if (result.error) {
                console.log('[Error]', result.error);
                if (result.error.code === "payment_intent_authentication_failure") {
                    setTopUpState('pp-failed');
                } else {
                    setTopUpState('unknown-error');
                }
            } else {
                if (result.paymentIntent.status === 'succeeded') {
                    const response = await fetch('https://api.datamenu.co.uk/confirm-payment', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${sessionToken}`
                        },
                        body: JSON.stringify({
                            userId: paymentInitial.userId,
                            paymentIntentId: result.paymentIntent.id
                        })
                    });

                    const responseData = await response.json();
                    if (responseData.result === 'success') {
                        setTopUpState('payment-complete');
                    } else {
                        console.error('Failed to update credits');
                    }
                }
            }
        }
    };

    const CARD_ELEMENT_OPTIONS = {
        style: {
            base: {
                color: "#888888",
                fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
                fontSmoothing: "antialiased",
                fontSize: "16px",
                "::placeholder": {
                    color: "#aab7c4"
                }
            },
            invalid: {
                color: "#fa755a",
                iconColor: "#fa755a"
            }
        }
    };

    return (
        <form onSubmit={handleSubmit} className='stripe-form'>
            {topUpState === 'initial' && (
                <div className='amountInputDiv'>
                    <h3>How many credits would you like to top-up?</h3>
                    <input
                        className='amountInput'
                        type="text"
                        name="amount"
                        value={paymentInitial.amount}
                        onChange={handleInputChange}
                    ></input>
                    <div className='amountSelections'>
                        <div className='amountSelect' value="500" onClick={() => handlePresetClick(500)}>500</div>
                        <div className='amountSelect' value="1000" onClick={() => handlePresetClick(1000)}>1000</div>
                        <div className='amountSelect' value="2500" onClick={() => handlePresetClick(2500)}>2500</div>
                        <div className='amountSelect' value="5000" onClick={() => handlePresetClick(5000)}>5000</div>
                        <div className='amountSelect' value="10000" onClick={() => handlePresetClick(10000)}>10000</div>
                    </div>
                    <p>Looking for daily credits? - Check out our <Link to="/subscriptions">packages</Link> here</p>
                </div>
            )}
            {topUpState === 'too-low' && (
                <div className='amountInputDiv'>
                    <h3>How many credits would you like to top-up?</h3>
                    <p>A minimum of 500 credits per transaction</p>
                    <input
                        className='amountInput'
                        type="text"
                        name="amount"
                        value={paymentInitial.amount}
                        onChange={handleInputChange}
                    ></input>
                    <div className='amountSelections'>
                        <div className='amountSelect' value="500" onClick={() => handlePresetClick(500)}>500</div>
                        <div className='amountSelect' value="1000" onClick={() => handlePresetClick(1000)}>1000</div>
                        <div className='amountSelect' value="2500" onClick={() => handlePresetClick(2500)}>2500</div>
                        <div className='amountSelect' value="5000" onClick={() => handlePresetClick(5000)}>5000</div>
                        <div className='amountSelect' value="10000" onClick={() => handlePresetClick(10000)}>10000</div>
                    </div>
                    <p>Looking for daily credits? - Check out our <Link to="/subscriptions">packages</Link> here</p>
                </div>
            )}
            {topUpState === 'payment-complete' && (
                <div className='amountInputDiv'>
                    <h3>Payment Complete - Your Tokens Have Been Added To Your Balance</h3>
                    <input
                        className='amountInput'
                        type="text"
                        name="amount"
                        value={paymentInitial.amount}
                        onChange={handleInputChange}
                    ></input>
                    <div className='amountSelections'>
                        <div className='amountSelect' value="500" onClick={() => handlePresetClick(500)}>500</div>
                        <div className='amountSelect' value="1000" onClick={() => handlePresetClick(1000)}>1000</div>
                        <div className='amountSelect' value="2500" onClick={() => handlePresetClick(2500)}>2500</div>
                        <div className='amountSelect' value="5000" onClick={() => handlePresetClick(5000)}>5000</div>
                        <div className='amountSelect' value="10000" onClick={() => handlePresetClick(10000)}>10000</div>
                    </div>
                    <p>Looking for daily credits? - Check out our <Link to="/subscriptions">packages</Link> here</p>
                </div>
            )}
            {topUpState === 'loading' && (
                <div className='amountInputDiv'>
                    <Loading />
                    <h3>Processing Payment...</h3>
                </div>
            )}
            {topUpState === 'pp-failed' && (
                <div className='amountInputDiv'>
                    <h3>Payment could not be authorised</h3>
                    <input
                        className='amountInput'
                        type="text"
                        name="amount"
                        value={paymentInitial.amount}
                        onChange={handleInputChange}
                    ></input>
                    <div className='amountSelections'>
                        <div className='amountSelect' value="500" onClick={() => handlePresetClick(500)}>500</div>
                        <div className='amountSelect' value="1000" onClick={() => handlePresetClick(1000)}>1000</div>
                        <div className='amountSelect' value="2500" onClick={() => handlePresetClick(2500)}>2500</div>
                        <div className='amountSelect' value="5000" onClick={() => handlePresetClick(5000)}>5000</div>
                        <div className='amountSelect' value="10000" onClick={() => handlePresetClick(10000)}>10000</div>
                    </div>
                    <p>Looking for daily credits? - Check out our <Link to="/subscriptions">packages</Link> here</p>
                </div>
            )}
            {topUpState === 'unknown-error' && (
                <div className='amountInputDiv'>
                    <h3>An Unknown Error Has Occured</h3>
                    <p>Please try again in a few minutes</p>
                </div>
            )}
            <div className='billing-details'>
                <input
                    type="text"
                    name="name"
                    value={billingDetails.name}
                    onChange={handleInputChangeAddress}
                    placeholder="Name"
                />
                <input
                    type="email"
                    name="email"
                    value={billingDetails.email}
                    onChange={handleInputChangeAddress}
                    placeholder="Email"
                />
                <select name="country" value={billingDetails.address.country} onChange={handleInputChangeAddress}>
                    <option value="">Select Country</option>
                    <option value="US">United States</option>
                    <option value="GB">United Kingdom</option>
                </select>
                <input
                    type="text"
                    name="city"
                    value={billingDetails.address.city}
                    onChange={handleInputChangeAddress}
                    placeholder="City"
                />
                <input
                    type="text"
                    name="state"
                    value={billingDetails.address.state}
                    onChange={handleInputChangeAddress}
                    placeholder="County / State"
                />
                <input
                    type="text"
                    name="postal_code"
                    value={billingDetails.address.postal_code}
                    onChange={handleInputChangeAddress}
                    placeholder="Postal Code"
                />
            </div>
            <div className='card-details'>
                <CardNumberElement options={CARD_ELEMENT_OPTIONS} />
                <CardExpiryElement options={CARD_ELEMENT_OPTIONS} />
                <CardCvcElement options={CARD_ELEMENT_OPTIONS} />
            </div>
            <button type="submit" disabled={!stripe} className="pay-button">
                <i className="fa fa-credit-card"></i> Pay
            </button>
        </form>
    );
};

const StripeContainer = () => {
    return (
        <Elements stripe={stripePromise}>
            <CheckoutForm />
        </Elements>
    );
};

export default StripeContainer;

import React from 'react';
import './Terms.css';

const Terms = () => {
    return (
        <div className="terms-container">
            <h1>Terms of Service</h1>
            <p>Last updated: 21/05/2024</p>
            <p>Welcome to Data Menu Limited's Terms of Service. These terms govern your use of our services at saas.datamenu.co.uk. By using our services, you agree to comply with these terms.</p>

            <h2>1. General Information</h2>
            <p>Data Menu - SaaS provides users with the ability to collect information from the web from various sources in exchange for credits. These credits can be purchased on a pay-as-you-go basis or via a subscription for weekly top-ups. Users can preview and download data, and they have the right to sell the data collected from these public sources.</p>

            <h2>2. Account Creation</h2>
            <p>To create an account, you must provide a valid email address. Providing incorrect information may lead to account restrictions. If a user tops up with money on a 'fake' account, the account may be closed if deemed fraudulent, and no refund will be awarded. Accounts will be verified in our backend to prevent users from creating multiple accounts for free trials. If found, the user will be terminated from using the service permanently.</p>

            <h2>3. User Obligations</h2>
            <p>Users are expected to:</p>
            <ul>
                <li>Provide accurate and truthful information during account creation and use of the service.</li>
                <li>Keep their account information confidential and not share their account with others.</li>
                <li>Use the service in compliance with all applicable laws and regulations.</li>
            </ul>
            <p>Users must not engage in the following activities:</p>
            <ul>
                <li>Spamming, hacking, or using exploits to manipulate the service.</li>
                <li>Creating multiple accounts for the purpose of exploiting free trials.</li>
                <li>Using the service in any way that could harm or disrupt the service or its users.</li>
            </ul>
            <p>Violations of these obligations may result in the suspension or termination of your account, and any credits or payments made will not be refunded.</p>

            <h2>4. Termination</h2>
            <p>We reserve the right to terminate any account under the circumstances described above, including fraudulent activities, misuse of the service, or violation of these terms. Terminated accounts will lose access to the service, and any remaining credits will be forfeited.</p>

            <h2>5. Disclaimers and Limitation of Liability</h2>
            <p>The accuracy of the data is dependent on the sources from which it is collected. We act as a facilitator for data collection and cannot guarantee the completeness or accuracy of the data. Users acknowledge that:</p>
            <ul>
                <li>Not all data fields may be present in every collected dataset.</li>
                <li>No refunds will be given for incomplete data.</li>
            </ul>
            <p>Data Menu will not be held liable for:</p>
            <ul>
                <li>Any direct, indirect, incidental, or consequential damages arising from the use of the service.</li>
                <li>Restrictions or bans from other websites due to excessive use of our data collection service.</li>
            </ul>

            <h2>6. Governing Law</h2>
            <p>These terms are governed by the laws of England, UK. Any disputes arising from the use of our service will be subject to the jurisdiction of the English courts.</p>

            <h2>7. Changes to Terms</h2>
            <p>We may update these Terms of Service from time to time. Any changes will be noted on the users' dashboard. We encourage you to review these terms periodically to stay informed about your rights and obligations.</p>

            <p>If you have any questions or concerns about these Terms of Service, please contact us at <a href="mailto:corporate@datamenu.co.uk">corporate@datamenu.co.uk</a>.</p>
        </div>
    );
};

export default Terms;

import React, { useState, useEffect } from 'react';
import './FreeForm.css';
import Loading from '../components/Loading';

const GoogleSearch = () => {
    const [searchTerm, setSearchData] = useState('');
    const [pageNum, setPageNum] = useState(1);
    const [userId, setUserId] = useState('');
    const [authToken, setAuthToken] = useState('');
    const [searchState, setSearchState] = useState('initial');
    const [dataDownload, setDataDownload] = useState('');

    const handleDownload = (data, filename = 'download.txt', mimeType = 'text/csv') => {
        var blob = new Blob([data], { type: mimeType });
        var url = URL.createObjectURL(blob);
        var downloadLink = document.createElement("a");
        downloadLink.download = filename;
        downloadLink.href = url;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        URL.revokeObjectURL(url);
        downloadLink.remove();
    };

    const handleSearchTerm = (event) => {
        setSearchData(event.target.value)
    }

    const handlePageNum = (event) => {
        setPageNum(event.target.value)
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        setSearchState('initial-loading');

        const userInfo = await fetch('https://api.datamenu.co.uk/getUser', {
            method: 'POST',
            body: JSON.stringify({ userId: userId })
        });
        const userRes = await userInfo.json();
        var freeCredits = userRes.data.freeCredits;
        if (freeCredits > 0) {
            const searchResponse = await fetch('https://api.datamenu.co.uk/google-search-free', {
                headers: {
                    Authorization: `Bearer ${authToken}`,
                    'Content-Type': 'application/json'
                },
                method: 'POST',
                body: JSON.stringify({ searchTerm: searchTerm, userId: userId, freeCredits: freeCredits, pageNum: pageNum })
            });
            const searchResult = await searchResponse.json();
            if (searchResult.result === 'success') {
                if (searchResult.data) {
                    var downloadDataItem = searchResult.data;
                    setDataDownload(downloadDataItem);
                    setSearchState('initial-jobdone');
                }
            }
        } else {
            setSearchState('initial-nocredit')
        }
    }

    useEffect(() => {
        const storedUserId = localStorage.getItem('userId');
        const storedAuthToken = localStorage.getItem('authToken');
        if (storedUserId) {
            setUserId(storedUserId);
            setAuthToken(storedAuthToken);
        }
    }, []);

    return (
        <section className='dm-free-tool-section'>
            {searchState.includes('loading') && (
                <Loading />
            )}
            <form className='dm-free-tool-form' onSubmit={handleSubmit}>
                <input
                    className='dm-free-tool-input'
                    value={searchTerm}
                    onChange={handleSearchTerm}
                    placeholder='Search Term'
                />
                <input
                    className='dm-free-tool-input'
                    type='number'
                    value={pageNum}
                    onChange={handlePageNum}
                    placeholder='Page Number'
                />
                <button className='dm-free-tool-button' type='submit'>Get Search Results</button>
                {searchState.includes('nocredit') && (
                    <div className='dm-free-tool-section'>You are out of credit for today, please try again tomorrow</div>
                )}
            </form>
            {searchState.includes('jobdone') && (
                <div className='dm-free-tool-section'>
                    <br />
                    <button className='dm-free-tool-button' onClick={() => handleDownload(dataDownload, searchTerm)}>Download Results</button>
                </div>
            )}
        </section>
    )
};

export default GoogleSearch;

import React, { useState } from 'react';
import './ToolPage.css'
import GoogleSearchForm from '../tool-components/GoogleSearchForm';
import logo from '../../components/tool-images/googlesearch.png';
import Pricing from './components/Pricing';

const GoogleSearch = () => {
    const [activeTab, setActiveTab] = useState('standard');

    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };

    return (
        <section className="tool-page-container">
            <div className="container">
                <div className="left-column">
                    <div className="image-row">
                        <img src={logo} alt="Google Search Tool Logo" className="image" />
                    </div>
                    <div className="form-row">
                        <GoogleSearchForm />
                    </div>
                </div>
                <div className="right-column">
                    <div className="content">
                        <Pricing rows={1} cost={10} title="Google Search Tool Pricing" />
                    </div>
                </div>
            </div>
            <div className='description-output'>
                <div className='description'>
                    <p>Introducing our powerful SaaS tool designed to streamline your data collection process. Our tool efficiently scours Google search results to gather comprehensive information and compile it into a neatly organized Excel file. With just a few clicks, you can obtain crucial details about your search queries, saving you time and effort.</p>
                    <p>Our tool is perfect for market researchers, business analysts, and anyone needing accurate, up-to-date information from Google search results. Simplify your data collection with our efficient SaaS solution and get the information you need in a format that's easy to work with and analyze.</p>
                </div>

                <h3>Available Outputs</h3>
                <div className="tabs">
                    <button className={activeTab === 'standard' ? 'active' : ''} onClick={() => handleTabClick('standard')}>Standard Search</button>
                    <button className={activeTab === 'images' ? 'active' : ''} onClick={() => handleTabClick('images')}>Images</button>
                    <button className={activeTab === 'places' ? 'active' : ''} onClick={() => handleTabClick('places')}>Places</button>
                    <button className={activeTab === 'shopping' ? 'active' : ''} onClick={() => handleTabClick('shopping')}>Shopping</button>
                    <button className={activeTab === 'scholar' ? 'active' : ''} onClick={() => handleTabClick('scholar')}>Scholar</button>
                </div>

                {activeTab === 'standard' && (
                    <div className='output'>
                        <h4>Standard Search Output</h4>
                        <table>
                            <thead>
                                <tr>
                                    <th>Title</th>
                                    <th>Link</th>
                                    <th>Snippet</th>
                                    <th>Position</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Example Title</td>
                                    <td>https://example.com</td>
                                    <td>This is a snippet of the search result.</td>
                                    <td>1</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                )}

                {activeTab === 'images' && (
                    <div className='output'>
                        <h4>Images Output</h4>
                        <table>
                            <thead>
                                <tr>
                                    <th>Title</th>
                                    <th>Image URL</th>
                                    <th>Image Width</th>
                                    <th>Image Height</th>
                                    <th>Thumbnail URL</th>
                                    <th>Thumbnail Height</th>
                                    <th>Thumbnail Width</th>
                                    <th>Source Domain</th>
                                    <th>Link</th>
                                    <th>Google URL</th>
                                    <th>Position</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Example Image</td>
                                    <td>https://example.com/image.jpg</td>
                                    <td>800</td>
                                    <td>600</td>
                                    <td>https://example.com/thumbnail.jpg</td>
                                    <td>150</td>
                                    <td>150</td>
                                    <td>example.com</td>
                                    <td>https://example.com</td>
                                    <td>https://google.com/search?tbm=isch&q=example</td>
                                    <td>1</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                )}

                {activeTab === 'places' && (
                    <div className='output'>
                        <h4>Places Output</h4>
                        <table>
                            <thead>
                                <tr>
                                    <th>Title</th>
                                    <th>Address</th>
                                    <th>Latitude</th>
                                    <th>Longitude</th>
                                    <th>Rating</th>
                                    <th>Rating Count</th>
                                    <th>Category</th>
                                    <th>Website</th>
                                    <th>CID</th>
                                    <th>Position</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Example Place</td>
                                    <td>123 Example St, Example City</td>
                                    <td>40.7128</td>
                                    <td>-74.0060</td>
                                    <td>4.5</td>
                                    <td>100</td>
                                    <td>Restaurant</td>
                                    <td>https://example.com</td>
                                    <td>1234567890</td>
                                    <td>1</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                )}

                {activeTab === 'shopping' && (
                    <div className='output'>
                        <h4>Shopping Output</h4>
                        <table>
                            <thead>
                                <tr>
                                    <th>Title</th>
                                    <th>Source</th>
                                    <th>Link</th>
                                    <th>Price</th>
                                    <th>Delivery</th>
                                    <th>Image URL</th>
                                    <th>Position</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Example Product</td>
                                    <td>Example Store</td>
                                    <td>https://example.com/product</td>
                                    <td>$19.99</td>
                                    <td>Free</td>
                                    <td>https://example.com/product.jpg</td>
                                    <td>1</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                )}

                {activeTab === 'scholar' && (
                    <div className='output'>
                        <h4>Scholar Output</h4>
                        <table>
                            <thead>
                                <tr>
                                    <th>Title</th>
                                    <th>Link</th>
                                    <th>Publication Info</th>
                                    <th>Snippet</th>
                                    <th>Year</th>
                                    <th>Cited By</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Example Paper</td>
                                    <td>https://example.com/paper</td>
                                    <td>Journal of Examples, Vol. 1</td>
                                    <td>This is a snippet of the paper.</td>
                                    <td>2023</td>
                                    <td>10</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                )}
            </div>
        </section>
    );
};

export default GoogleSearch;

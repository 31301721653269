import React from 'react';
import ContactForm from '../components/ContactForm';
import './DataCollection.css';

const DataCollection = () => {
    return (
        <div className="data-collection-container">
            <h1>Request Data Collection</h1>
            <p>Need specific data collected? We can gather the data you need efficiently and accurately. Fill out the form below to request a quote.</p>
            <ContactForm />
        </div>
    );
};

export default DataCollection;

import React from 'react';
import ContactForm from '../components/ContactForm';
import './CustomSolutions.css';

const CustomSolutions = () => {
    return (
        <div className="custom-solutions-container">
            <h1>Request a Custom Solution</h1>
            <p>Looking for a bespoke data solution? We can help automate platforms or software to meet your unique needs. Fill out the form below to request a quote.</p>
            <ContactForm />
        </div>
    );
};

export default CustomSolutions;

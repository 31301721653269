import React, { useState, useEffect } from 'react';
import './RecentRuns.css';
import Modal from '../components/Modal';

const RecentRuns = () => {
    const [dataEntries, setDataEntries] = useState([]);
    const [filteredEntries, setFilteredEntries] = useState([]);
    const [showPreview, setShowPreview] = useState(false);
    const [previewData, setPreviewData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(5);
    const [searchTerm, setSearchTerm] = useState('');

    const handleDownload = (data, filename = 'download.csv', mimeType = 'text/csv') => {
        var blob = new Blob([data], { type: mimeType });
        var url = URL.createObjectURL(blob);
        var downloadLink = document.createElement("a");
        downloadLink.download = filename;
        downloadLink.href = url;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        URL.revokeObjectURL(url);
        downloadLink.remove();
    };

    useEffect(() => {
        const fetchRecentRuns = async () => {
            const userId = localStorage.getItem('userId');
            if (!userId) return;

            try {
                const response = await fetch('https://api.datamenu.co.uk/get-recent-data', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ userId })
                });

                if (!response.ok) throw new Error('Failed to fetch recent runs');

                const result = await response.json();
                if (result.result === 'success' && result.data) {
                    setDataEntries(result.data);
                    setFilteredEntries(result.data);
                } else {
                    console.error('Failed to fetch data:', result.message);
                }
            } catch (error) {
                console.error('Error fetching recent runs:', error);
            }
        };

        fetchRecentRuns();
    }, []);

    useEffect(() => {
        const results = dataEntries.filter(entry =>
            entry.query.toLowerCase().includes(searchTerm.toLowerCase()) ||
            entry.tool.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setFilteredEntries(results);
        setCurrentPage(1);
    }, [searchTerm, dataEntries]);

    const handlePreview = (data) => {
        const rows = data.split('\n').slice(0, 11);
        const formattedData = rows.map(row => row.split('","').map(cell => cell.replace(/^"|"$/g, '')));
        setPreviewData(formattedData);
        setShowPreview(true);
    };

    const handlePageChange = (event) => {
        setCurrentPage(Number(event.target.value));
    };

    const handleItemsPerPageChange = (event) => {
        setItemsPerPage(Number(event.target.value));
        setCurrentPage(1); // Reset to first page with new items per page
    };

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredEntries.slice(indexOfFirstItem, indexOfLastItem);

    return (
        <section className='recent-runs'>
            <Modal show={showPreview} onClose={() => setShowPreview(false)} data={previewData} />
            <h1>Recent Data Runs</h1>
            <input
                type="text"
                placeholder="Search recent runs..."
                value={searchTerm}
                onChange={handleSearchChange}
                className="search-input"
            />
            <div className="pagination-controls">
                Items per page:
                <select value={itemsPerPage} onChange={handleItemsPerPageChange}>
                    <option value="5">5</option>
                    <option value="10">10</option>
                    <option value="25">25</option>
                </select>
            </div>
            <table>
                <thead>
                    <tr>
                        <th>Query</th>
                        <th>Source</th>
                        <th>Rows</th>
                        <th>Date</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {currentItems.map(entry => (
                        <tr key={entry._id}>
                            <td>{entry.query}</td>
                            <td>{entry.tool}</td>
                            <td>{entry.totalRows}</td>
                            <td>{new Date(entry.createdAt).toLocaleDateString()}</td>
                            <td>
                                <button onClick={() => handleDownload(entry.data, `${entry.query}.csv`)} className="btn btn-download">
                                    Download
                                </button>
                                <button onClick={() => handlePreview(entry.data)} className="btn btn-preview">
                                    Preview
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            {filteredEntries.length > itemsPerPage && (
                <div className="pagination">
                    Page:
                    {[...Array(Math.ceil(filteredEntries.length / itemsPerPage)).keys()].map(number => (
                        <button
                            key={number + 1}
                            onClick={() => setCurrentPage(number + 1)}
                            disabled={currentPage === number + 1}
                            className={currentPage === number + 1 ? 'active' : ''}
                        >
                            {number + 1}
                        </button>
                    ))}
                </div>
            )}
        </section>
    );
};

export default RecentRuns;

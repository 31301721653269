import React from 'react';
import StripeContainer from '../components/StripeContainer';
import './PurchaseCredits.css';

const PurchaseCredits = () => {
    return (
        <div className="purchase-credits-container">
            <h1>Purchase Credits</h1>
            <StripeContainer />
        </div>
    );
};

export default PurchaseCredits;

import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ConradBrothers from './pages/client-hosted/ConradBrothers/ConradBrothers';
import SocialsCollector from './pages/client-hosted/SocialsCollector/SocialsCollector';
import FCPayment from './pages/FCPayment';
import FCPrivacy from './pages/FCPrivacy';



// Crypto
import TokenDashboard from './pages/TokenDashboard';

const ClientHostedApp = () => {
  return (
    <Router>
      <Routes>
        <Route path="/conrad-brothers" element={<ConradBrothers />} />
        <Route path="/socials-collector" element={<SocialsCollector />} />
        <Route path="/moonshot" element={<TokenDashboard />} />
        <Route path="/fc-auto-snipe-checkout" element={<FCPayment />} />
        <Route path="/fc-privacy" element={<FCPrivacy />} />
      </Routes>
    </Router>
  );
};

export default ClientHostedApp;

import React from 'react';
import './ToolPage.css';
import BBBForm from '../tool-components/BBBForm';
import logo from '../../components/tool-images/bbb.png';
import Pricing from './components/Pricing';

const BBB = () => {
    return (
        <section className="tool-page-container">
            <div className="container">
                <div className="left-column">
                    <div className="image-row">
                        <img src={logo} alt="BBB Logo" className="image" />
                    </div>
                    <div className="form-row">
                        <BBBForm />
                    </div>
                </div>
                <div className="right-column">
                    <div className="content">
                        <Pricing rows={1} cost={10} title="BBB Search Pricing" />
                    </div>
                </div>
            </div>
            <div className='description-output'>
                <div className='description'>
                    <p>Introducing our powerful SaaS tool designed to streamline your data collection process. Our tool efficiently scours the BBB.org website to gather comprehensive company information and compiles it into a neatly organized Excel file. With just a few clicks, you can obtain crucial details about businesses, saving you time and effort.</p>
                    <p>Our tool is perfect for market researchers, business analysts, and anyone needing accurate, up-to-date information on companies listed on BBB.org. Simplify your data collection with our efficient SaaS solution and get the information you need in a format that's easy to work with and analyze.</p>
                </div>
                <h3>The Output</h3>
                <div className='output'>
                    <table>
                        <thead>
                            <tr>
                                <th>Header</th>
                                <th>Description</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>URL</td>
                                <td>The BBB Url</td>
                            </tr>
                            <tr>
                                <td>Name</td>
                                <td>The company name</td>
                            </tr>
                            <tr>
                                <td>Phone Number</td>
                                <td>The company phone number</td>
                            </tr>
                            <tr>
                                <td>Location</td>
                                <td>The company's location / origin</td>
                            </tr>
                            <tr>
                                <td>Team Members</td>
                                <td>The list of team members and roles within the company</td>
                            </tr>
                            <tr>
                                <td>Website</td>
                                <td>The company's website</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </section>
    );
};

export default BBB;

import React, { useState } from 'react';
import './Home.css';
import Counter from '../components/Counter';
import FreeTool from '../components/FreeTool';
import './Core.css';
import './FreeTools.css';
import { useAuth } from '../context/AuthContext';

// FREE TOOL IMAGES
import googleSearchImage from '../components/tool-images/googlesearch.png';
import makecsvImage from '../components/tool-images/makecsv.png';

// FREE TOOLS
import GoogleSearch from '../freetools/GoogleSearch';
import MakeCSV from '../freetools/MakeCSV';

const FreeTools = () => {
    const [freeToolState, setFreeToolState] = useState('initial');
    const { isAuthenticated } = useAuth();

    const handleStateChange = (event) => {
        const newState = event.target.getAttribute('value');
        if (newState) {
            setFreeToolState(newState);
        }
    };

    return (
        <div className="home-container">
            {freeToolState === 'initial' && (
                <section className='sources'>
                    <h2><Counter number={2} speed={1} /> Free Tools Available</h2>
                    <div className="tools-grid">
                        <FreeTool name="Make CSV" id="tool-makecsv" image={makecsvImage} handleStateChange={handleStateChange} />
                        <FreeTool name="Google Search" id="tool-googlesearch" image={googleSearchImage} handleStateChange={handleStateChange} />
                    </div>
                </section>
            )}
            {freeToolState.includes('tool') && (
                <div className='toolArea'>
                    <button value="initial" onClick={handleStateChange} className="back-button">Back</button>

                    {freeToolState.includes('makecsv') && (
                        <div className='theTool'>
                            <h1>Make CSV</h1>
                            <MakeCSV />
                        </div>
                    )}

                    {freeToolState.includes('googlesearch') && (
                        <div className='theTool'>
                            <h1>Google Search Results</h1>
                            {isAuthenticated ? <GoogleSearch /> : <p>You must be logged in to use this tool</p>}
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default FreeTools;

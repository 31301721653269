import React from 'react';
import './PremiumTools.css';
import Featured from '../components/Featured';
import Categories from '../components/Categories';

const PremiumTools = () => {
    return (
        <div className="premium-tools-container">
            <h1>PREMIUM SOFTWARE</h1>
            <hr/>
            <h3>Most Popular</h3>
            <Featured />
            <h3>Categories</h3>
            <Categories />
        </div>
    );
};

export default PremiumTools;

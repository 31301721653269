import React, { createContext, useContext, useState, useEffect } from 'react';
import {jwtDecode} from 'jwt-decode';

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [userCredit, setUserCredit] = useState(0);
    const [userRole, setUserRole] = useState('free');

    const fetchUserCredit = async () => {
        var token = localStorage.getItem('authToken');
        var userId = localStorage.getItem('userId');

        if (token) {

            // Replace with your API endpoint to fetch user details or credit
            const response = await fetch('https://api.datamenu.co.uk/get-credits', {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "DM-User_ID": userId
                },
                method: 'POST'
            });
            if (response.ok) {
                const data = await response.json();
                setUserCredit(data.total_credits);
                setUserRole(data.role);
            }
        }
    };

    fetchUserCredit()

    // Function to log in the user
    const login = (token, uid) => {
        localStorage.setItem('authToken', token);
        localStorage.setItem('userId', uid)
        setIsAuthenticated(true);
    };

    // Function to log out the user
    const logout = () => {
        localStorage.removeItem('authToken');  // Remove the token from localStorage
        setIsAuthenticated(false);
    };

    // Effect to check auth token on initial load
    useEffect(() => {
        const token = localStorage.getItem('authToken');
        if (token) {
            const decodedToken = jwtDecode(token);
            const now = Date.now() / 1000;
            if (decodedToken.exp < now) {
                logout();
            } else {
                setIsAuthenticated(true);
            }
        }
    }, []);

    return (
        <AuthContext.Provider value={{ isAuthenticated, userCredit, userRole, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './TokenDashboard.css';

const TokenDashboard = () => {
    const [tokens, setTokens] = useState({
        newTokens: [],
        risingTokens: [],
        trendingTokens: [],
        graduatedTokens: [],
        oneToWatch: null,
    });
    const [loading, setLoading] = useState(true);
    const [lastUpdated, setLastUpdated] = useState(null);

    useEffect(() => {
        const fetchTokens = async () => {
            try {
                const response = await axios.get('/tokens');
                setTokens(response.data);
                setLastUpdated(new Date().toISOString());
            } catch (error) {
                console.error('Error fetching tokens:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchTokens();
        const interval = setInterval(fetchTokens, 10000);

        return () => clearInterval(interval);
    }, []);

    const renderTokenCard = (token) => (
        <div className="token-card" key={token.pairAddress}>
            <img src={token.image} alt={token.name} className="token-icon" />
            <div className="token-info">
                <h3>{token.name} ({token.symbol})</h3>
                <p>Progress: {token.progress}%</p>
                <p>Market Cap: ${token.mcap}</p>
                <p>Liquidity: ${token.liquidity}</p>
                <p>Buys: {token.buys} / Sells: {token.sells}</p>
                <a href={token.tickerLink} target="_blank" rel="noopener noreferrer">View on DexScreener</a>
            </div>
        </div>
    );

    const renderSidebarItem = (item) => (
        <div className="sidebar-item" key={item.pairAddress}>
            <h4>{item.name}</h4>
            <p>{item.progress}%</p>
        </div>
    );

    const renderLoading = () => (
        <div className="loading">
            <div className="spinner"></div>
            <p>Loading...</p>
        </div>
    );

    return (
        <div className="token-dashboard">
            {tokens.oneToWatch && (
                <div className="one-to-watch-container">
                    <h2>One to Watch</h2>
                    {tokens.oneToWatch ? renderTokenCard(tokens.oneToWatch) : renderLoading()}
                </div>
            )}
            <div className="sidebar">
                <h2>Keywords</h2>
                {loading || !tokens.trendingTokens || !tokens.trendingTokens.length ? renderLoading() : tokens.trendingTokens.map(token => renderSidebarItem(token))}
            </div>
            <div className="main-content">
                <div className="tokens-section">
                    <h2>Rising Tokens</h2>
                    <div className="tokens-list">
                        {loading || !tokens.risingTokens || !tokens.risingTokens.length ? renderLoading() : tokens.risingTokens.map(token => renderTokenCard(token))}
                    </div>
                </div>
                <div className="tokens-section">
                    <h2>Trending Tokens</h2>
                    <div className="tokens-list">
                        {loading || !tokens.trendingTokens || !tokens.trendingTokens.length ? renderLoading() : tokens.trendingTokens.map(token => renderTokenCard(token))}
                    </div>
                </div>
            </div>
            <div className="sidebar">
                <h2>Graduated Tokens</h2>
                {loading || !tokens.graduatedTokens || !tokens.graduatedTokens.length ? renderLoading() : tokens.graduatedTokens.map(token => renderSidebarItem(token))}
            </div>
            {lastUpdated && (
                <div className="last-updated">
                    <p>Last Updated: {new Date(lastUpdated).toUTCString()}</p>
                </div>
            )}
        </div>
    );
};

export default TokenDashboard;

import React from 'react';
import './CategoryPage.css';
import Tool from './components/ToolItem';

import bbbImage from '../../components/tool-images/bbb.png';
import ypusaImage from '../../components/tool-images/yellowpagesusa.png';

const Directories = () => {
    const tools = [
        { name: 'BBB', image: bbbImage, path: '/bbb' },
        { name: 'Yellow Pages USA', image: ypusaImage, path: '/Yellow-Pages-USA' }
    ];

    return (
        <section className='category-page-head'>
            <h1>Directory Tools</h1>
            <section className='tool-grid'>
                {tools.map((tool, index) => (
                    <Tool key={index} name={tool.name} image={tool.image} path={tool.path} />
                ))}
            </section>
        </section>
    );
};

export default Directories;

import React, { useState } from 'react';
import axios from 'axios';
import './AdminInvoice.css';

const AdminInvoice = () => {
    const [email, setEmail] = useState('');
    const [items, setItems] = useState([{ description: '', price: '' }]);

    const handleItemChange = (index, field, value) => {
        const newItems = [...items];
        newItems[index][field] = value;
        setItems(newItems);
    };

    const addItem = () => {
        setItems([...items, { description: '', price: '' }]);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('https://api.datamenu.co.uk/invoices', { email, items });
            if (response.status === 201) {
                alert('Invoice created and sent!');
                setEmail('');
                setItems([{ description: '', price: '' }]);
            }
        } catch (error) {
            console.log(error);
            alert('Error creating invoice');
        }
    };

    return (
        <div className="admin-invoice-container">
            <h1>Create Invoice</h1>
            <form onSubmit={handleSubmit} className="invoice-form">
                <div className="form-group">
                    <label>Email</label>
                    <input
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                </div>
                {items.map((item, index) => (
                    <div className="form-group" key={index}>
                        <label>Item Description</label>
                        <input
                            type="text"
                            value={item.description}
                            onChange={(e) => handleItemChange(index, 'description', e.target.value)}
                            required
                        />
                        <label>Item Price</label>
                        <input
                            type="number"
                            value={item.price}
                            onChange={(e) => handleItemChange(index, 'price', e.target.value)}
                            required
                        />
                    </div>
                ))}
                <div className="button-group">
                    <button type="button" onClick={addItem}>Add Item</button>
                    <button type="submit">Send Invoice</button>
                </div>
            </form>
        </div>
    );
};

export default AdminInvoice;

import React, { useState, useEffect } from 'react';
import './SocialsCollector.css';
import axios from 'axios';
import Loading from '../../../components/Loading';

const SocialsCollector = () => {
    const [urlCount, setUrlCount] = useState({ valid: 0, invalid: 0 });
    const [invalidUrls, setInvalidUrls] = useState([]);
    const [processStatus, setProcessStatus] = useState('');
    const [loading, setLoading] = useState(false);
    const [apiKey, setApiKey] = useState('');
    const [spreadsheetUrl, setSpreadsheetUrl] = useState('');
    const [modalOpen, setModalOpen] = useState(false);

    useEffect(() => {
        const savedApiKey = localStorage.getItem('apiKey');
        const savedSpreadsheetUrl = localStorage.getItem('spreadsheetUrl');
        if (savedApiKey) setApiKey(savedApiKey);
        if (savedSpreadsheetUrl) setSpreadsheetUrl(savedSpreadsheetUrl);
    }, []);

    const handleUrlChange = (e) => {
        const urls = e.target.value.split('\n');
        let validCount = 0;
        let invalidCount = 0;
        let invalidUrlList = [];

        urls.forEach((url, index) => {
            if (!url.trim()) {
                invalidCount++;
                invalidUrlList.push({ line: index + 1, url: 'EMPTY' });
            } else if (url.toLowerCase().includes(document.getElementById('platform-select').value.toLowerCase() + '.com')) {
                validCount++;
            } else {
                invalidCount++;
                invalidUrlList.push({ line: index + 1, url });
            }
        });

        setUrlCount({ valid: validCount, invalid: invalidCount });
        setInvalidUrls(invalidUrlList);
    };

    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.onload = function(event) {
            const urls = event.target.result.split('\n');
            document.getElementById('urls-input').value = urls.join('\n');
            handleUrlChange({ target: { value: event.target.result } });
        };
        reader.readAsText(file);
    };

    const handleSaveDetails = () => {
        localStorage.setItem('apiKey', apiKey);
        localStorage.setItem('spreadsheetUrl', spreadsheetUrl);
        alert('Details saved successfully!');
    };

    const handleProcessUrls = async () => {
        const platform = document.getElementById('platform-select').value;
        const urlsInput = document.getElementById('urls-input').value;
        const urls = urlsInput.split('\n').map(url => url.trim()).filter(url => url);

        const spreadsheetId = spreadsheetUrl.match(/[-\w]{25,}/);

        if (!spreadsheetId) {
            setProcessStatus('Invalid Spreadsheet URL');
            return;
        }

        setLoading(true);
        try {
            const response = await axios.post('https://api.datamenu.co.uk/run-socials-collector', {
                platform,
                urls,
                apiKey,
                spreadsheetId: spreadsheetId[0]
            });
            
            if (response.data.result === 'success') {
                setProcessStatus('URLs processed successfully.');
            } else {
                console.log(response.data);
                setProcessStatus(`Error: ${response.data.message}`);
            }
        } catch (error) {
            console.error('Error processing URLs:', error);
            setProcessStatus('An error occurred while processing URLs.');
        } finally {
            setLoading(false);
        }
    };

    if (loading) {
        return <Loading />;
    }

    return (
        <div className="collector-container">
            <h1>Socials Collector</h1>
            <div className="form-group">
                <label htmlFor="platform-select">Select Platform</label>
                <select id="platform-select" onChange={(e) => handleUrlChange({ target: { value: document.getElementById('urls-input').value } })}>
                    <option value="YouTube">YouTube</option>
                    <option value="TikTok">TikTok</option>
                    <option value="Instagram">Instagram</option>
                </select>
            </div>
            <div className="form-group">
                <label htmlFor="urls-input">Enter URLs (one per line) (must start with http:// or https://)</label>
                <textarea id="urls-input" rows="10" cols="50" onChange={handleUrlChange}></textarea>
                <div id="url-count">
                    Valid URLs: {urlCount.valid}
                    {urlCount.invalid > 0 && (
                        <span>
                            {' | '}
                            Invalid URLs: {urlCount.invalid} 
                            <button id='modelOpen' onClick={() => setModalOpen(true)}>?</button>
                        </span>
                    )}
                </div>
            </div>
            <div className="form-group">
                <label htmlFor="file-upload">Or upload a file with URLs</label>
                <input type="file" id="file-upload" accept=".txt" onChange={handleFileUpload} />
            </div>
            <div className="form-group">
                <label htmlFor="spreadsheet-url">Spreadsheet URL</label>
                <input 
                    type="text" 
                    id="spreadsheet-url" 
                    placeholder="Enter Google Sheets URL" 
                    value={spreadsheetUrl}
                    onChange={(e) => setSpreadsheetUrl(e.target.value)}
                />
                <p className="spreadsheet-notice">Please ensure the Google Sheet has open access to anyone with the link.</p>
            </div>
            <div className="form-group">
                <label htmlFor="api-key">API Key</label>
                <input 
                    type="text" 
                    id="api-key" 
                    placeholder="Enter API Key" 
                    value={apiKey}
                    onChange={(e) => setApiKey(e.target.value)}
                />
            </div>
            <button id="save-button" onClick={handleSaveDetails}>Save Details</button>
            <button id="process-button" onClick={handleProcessUrls}>Process URLs</button>
            <div id="process-status" className="status-area">{processStatus}</div>

            {modalOpen && (
                <div className="modal">
                    <div className="modal-content">
                        <span className="close" onClick={() => setModalOpen(false)}>&times;</span>
                        <h2>Invalid URLs</h2>
                        <ul>
                            {invalidUrls.map((item, index) => (
                                <li key={index}>
                                    Line {item.line}: {item.url}
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            )}
        </div>
    );
};

export default SocialsCollector;

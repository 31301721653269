import React, { useState, useEffect } from 'react';
import { useAuth } from '../../context/AuthContext';
import Loading from '../../components/Loading';
import CheckoutForm from '../../components/StripeContainer';
import './FormStyles.css';

const BBBForm = () => {
    const { isAuthenticated } = useAuth();
    const [formState, setFormState] = useState('initial');
    const [formData, setFormData] = useState({
        search: '',
        country: '',
        location: '',
        userId: '',
        credits: ''
    });
    const [resultsData, setResultData] = useState({
        totalRows: 0,
        cost: 0,
        downloadData: ""
    });

    const handleDownload = (data, filename = 'download.txt', mimeType = 'text/csv') => {
        var blob = new Blob([data], { type: mimeType });
        var url = URL.createObjectURL(blob);
        var downloadLink = document.createElement("a");
        downloadLink.download = filename;
        downloadLink.href = url;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        URL.revokeObjectURL(url);
        downloadLink.remove();
    };

    useEffect(() => {
        const storedUserId = localStorage.getItem('userId');
        if (storedUserId) {
            setFormData(prevFormData => ({
                ...prevFormData,
                userId: storedUserId
            }));
        }
    }, []);

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: value
        }));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const authToken = localStorage.getItem('authToken');
        const userId = formData.userId;

        const creditResponse = await fetch('https://api.datamenu.co.uk/get-credits', {
            headers: {
                Authorization: `Bearer ${authToken}`,
                "DM-User_ID": userId
            },
            method: 'POST'
        });
        if (creditResponse.ok) {
            const creditsResult = await creditResponse.json();
            const credits = creditsResult.total_credits;
            formData.credits = credits;

            const endpoint = 'https://api.datamenu.co.uk/run-bbb';
            setFormState('loading');

            const response = await fetch(endpoint, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData)
            });

            const data = await response.json();
            var result = data.result;
            if (result === "success") {
                var totalRows = data.totalRows;
                var cost = data.cost;
                var downloadData = data.data;
                setResultData({
                    totalRows,
                    cost,
                    downloadData
                });

                const payResponse = await fetch('https://api.datamenu.co.uk/pay-for-data', {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${authToken}`,
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ userId: userId, amount: cost })
                });

                const payResult = await payResponse.json();
                if (payResult.result === 'success') {
                    const uploadData = await fetch('https://api.datamenu.co.uk/upload-data', {
                        method: 'POST',
                        headers: {
                            'Authorization': `Bearer ${authToken}`,
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({ userId: userId, query: `${formData.search} - ${formData.location} - ${formData.country}`, data: downloadData, totalRows, tool: 'BBB' })
                    });

                    const uploadResult = await uploadData.json();
                    setFormState('download');
                } else {
                    setFormState('error');
                }
            } else {
                if (data.reason === 'low balance') {
                    setFormState('top-up');
                }
            }
        }
    };

    return (
        <section className="dm-tool-section">
            {formState === 'initial' && (
                <div>
                    {isAuthenticated ? (
                        <form onSubmit={handleSubmit} className="dm-tool-form">
                            <h3>BBB Search</h3>
                            <label className="dm-tool-label">
                                Search Term:
                                <input
                                    required
                                    type="text"
                                    name="search"
                                    value={formData.search}
                                    onChange={handleInputChange}
                                    className="dm-tool-input"
                                />
                            </label>
                            <label className="dm-tool-label">
                                Country:
                                <select
                                    required
                                    name="country"
                                    value={formData.country}
                                    onChange={handleInputChange}
                                    className="dm-tool-select"
                                >
                                    <option value="" disabled>Select Country</option>
                                    <option value="CAN">Canada</option>
                                    <option value="USA">USA</option>
                                </select>
                            </label>
                            <label className="dm-tool-label">
                                Location:
                                <input
                                    required
                                    type="text"
                                    name="location"
                                    value={formData.location}
                                    onChange={handleInputChange}
                                    className="dm-tool-input"
                                />
                            </label>
                            <button type="submit" className="dm-tool-button">Search</button>
                        </form>
                    ) : (
                        <h3>You are not authorised to use this application</h3>
                    )}
                </div>
            )}
            {formState === 'download' && (
                <div>
                    <h3>{resultsData.totalRows} Rows Collected</h3>
                    <p>{resultsData.cost} Credits Spent</p>
                    <button onClick={() => handleDownload(resultsData.downloadData, `${formData.search}_${formData.location}_${formData.country}.csv`, 'text/plain')}>
                        Download
                    </button>
                    <div>
                        <button onClick={() => setFormState('initial')}>Collect Another Set</button>
                    </div>
                </div>
            )}
            {formState === 'error' && (
                <div>
                    <h3>Error</h3>
                    <p>There was a blip in our servers, please try again</p>
                    <div>
                        <button onClick={() => setFormState('initial')}>Collect Another Set</button>
                    </div>
                </div>
            )}
            {formState === 'top-up' && (
                <div>
                    <h3>Balance Too Low</h3>
                    <p>To proceed with a collection from BBB, you will require at least 10 credits</p>
                    <h3>Top Up Here</h3>
                    <div>
                        <CheckoutForm />
                    </div>
                </div>
            )}
            {formState === 'top-up-failed' && (
                <div>
                    <h3>Something Went Wrong</h3>
                    <p>Please try again later, you have not been charged</p>
                    <h3>Top Up Here</h3>
                    <div>
                        <CheckoutForm />
                    </div>
                </div>
            )}
            {formState === 'loading' && (
                <div>
                    <Loading />
                </div>
            )}
        </section>
    );
};

export default BBBForm;

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './TaskMeetingPage.css';

const TaskMeetingPage = () => {
    const [tasks, setTasks] = useState([]);
    const [meetings, setMeetings] = useState([]);
    const [taskForm, setTaskForm] = useState({
        title: '',
        description: '',
        value: '',
        platform: 'Fiverr',
        contact: '',
        deadline: ''
    });
    const [meetingForm, setMeetingForm] = useState({
        meetingTime: '',
        withWho: '',
        platform: 'Fiverr'
    });
    const [descriptionModal, setDescriptionModal] = useState({ isOpen: false, content: '' });
    const [taskModal, setTaskModal] = useState(false);
    const [meetingModal, setMeetingModal] = useState(false);

    useEffect(() => {
        fetchTasks();
        fetchMeetings();
        const interval = setInterval(() => {
            fetchTasks();
            fetchMeetings();
            checkNotifications();
        }, 60000 * 60); // Refresh tasks and meetings every 60 seconds
        return () => clearInterval(interval);
    }, []);

    const fetchTasks = async () => {
        const response = await axios.get('https://api.datamenu.co.uk/tasks');
        const sortedTasks = response.data.sort(sortTasks);
        setTasks(sortedTasks);
    };

    const fetchMeetings = async () => {
        const response = await axios.get('https://api.datamenu.co.uk/meetings');
        const sortedMeetings = response.data.sort((a, b) => new Date(a.meetingTime) - new Date(b.meetingTime));
        setMeetings(sortedMeetings);
    };

    const sortTasks = (a, b) => {
        if (a.priority !== b.priority) {
            return b.priority - a.priority;
        }
        if (new Date(a.deadline) !== new Date(b.deadline)) {
            return new Date(a.deadline) - new Date(b.deadline);
        }
        if (a.value !== b.value) {
            return b.value - a.value;
        }
        return a.contact.localeCompare(b.contact);
    };

    const handleSubmitTask = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('https://api.datamenu.co.uk/tasks', {
                ...taskForm,
                value: parseInt(taskForm.value)
            });
            if (response.status === 201) {
                fetchTasks();
                setTaskForm({
                    title: '',
                    description: '',
                    value: '',
                    platform: 'Fiverr',
                    contact: '',
                    deadline: ''
                });
                setTaskModal(false);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const handleSubmitMeeting = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('https://api.datamenu.co.uk/meetings', {
                ...meetingForm,
                meetingTime: new Date(meetingForm.meetingTime).toISOString()
            });
            if (response.status === 201) {
                fetchMeetings();
                setMeetingForm({
                    meetingTime: '',
                    withWho: '',
                    platform: 'Fiverr'
                });
                setMeetingModal(false);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const handleDeleteTask = async (taskId) => {
        try {
            await axios.delete(`https://api.datamenu.co.uk/tasks/${taskId}`);
            fetchTasks();
        } catch (error) {
            console.error(error);
        }
    };

    const handleDeleteMeeting = async (meetingId) => {
        try {
            await axios.delete(`https://api.datamenu.co.uk/meetings/${meetingId}`);
            fetchMeetings();
        } catch (error) {
            console.error(error);
        }
    };

    const formatDeadline = (deadline) => {
        const now = new Date();
        const deadlineDate = new Date(deadline);
        const timeDifference = deadlineDate - now;

        if (timeDifference <= 0) return 'Expired';

        const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
        const hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));

        if (days > 0) {
            return `${days}D ${hours}H`;
        } else {
            return `${hours}H ${minutes}M`;
        }
    };

    const checkNotifications = () => {
        tasks.forEach(task => {
            const timeDifference = new Date(task.deadline) - new Date();
            if (timeDifference <= 24 * 60 * 60 * 1000 && !task.notificationSent) {
                showNotification(`Task "${task.title}" is due in less than 24 hours.`);
                axios.patch(`https://api.datamenu.co.uk/tasks/${task._id}`, { notificationSent: true });
            }
        });

        meetings.forEach(meeting => {
            const timeDifference = new Date(meeting.meetingTime) - new Date();
            if (timeDifference <= 2 * 60 * 60 * 1000 && !meeting.twoHourNotificationSent) {
                showNotification(`Meeting with "${meeting.withWho}" is in less than 2 hours.`);
                axios.patch(`https://api.datamenu.co.uk/meetings/${meeting._id}`, { twoHourNotificationSent: true });
            } else if (timeDifference <= 15 * 60 * 1000 && !meeting.fifteenMinNotificationSent) {
                showNotification(`Meeting with "${meeting.withWho}" is in less than 15 minutes.`);
                axios.patch(`https://api.datamenu.co.uk/meetings/${meeting._id}`, { fifteenMinNotificationSent: true });
            }
        });
    };

    const showNotification = (message) => {
        if (Notification.permission === 'granted') {
            new Notification('Reminder', { body: message });
        }
    };

    const requestNotificationPermission = () => {
        if (Notification.permission !== 'granted') {
            Notification.requestPermission().then(permission => {
                if (permission === 'granted') {
                    console.log('Notification permission granted.');
                }
            });
        }
    };

    useEffect(() => {
        requestNotificationPermission();
    }, []);

    const openDescriptionModal = (description) => {
        setDescriptionModal({ isOpen: true, content: description });
    };

    const closeDescriptionModal = () => {
        setDescriptionModal({ isOpen: false, content: '' });
    };

    const getColorForValue = (value) => {
        if (value > 5000) return 'linear-gradient(to right, violet, indigo, blue, green, yellow, orange, red)';
        if (value > 3000) return 'purple';
        if (value > 1000) return 'gold';
        if (value > 50) return `hsl(${Math.min(120, value / 50 * 120)}, 100%, 50%)`;
        return 'grey';
    };

    const getDeadlineClass = (timeDifference) => {
        if (timeDifference <= 24 * 60 * 60 * 1000) return 'due-soon';
        if (timeDifference <= 48 * 60 * 60 * 1000) return 'upcoming';
        return '';
    };

    return (
        <div className="task-meeting-container">
            <h1>Tasks and Meetings</h1>
            <div className="button-group">
                <button onClick={() => setTaskModal(true)}>New Task</button>
                <button onClick={() => setMeetingModal(true)}>New Meeting</button>
            </div>
            <div className="list-container">
                <div className="task-list">
                    <h2>Tasks</h2>
                    {tasks.map((task) => (
                        <div key={task._id} className={`task-item ${new Date(task.deadline) - new Date() <= 0 ? 'expired' : ''}`}>
                            <div className="task-card">
                                <div className="task-details">
                                    <div className="task-value" style={{ background: getColorForValue(task.value) }}>
                                        £{task.value}
                                    </div>
                                    <h3>{task.title}</h3>
                                    <button onClick={() => openDescriptionModal(task.description)} className="description-btn">
                                        <i className="fa fa-question-circle"></i>
                                    </button>
                                    <div className="task-info">
                                        <div className="task-platform">
                                            <img
                                                src={task.platform === 'Fiverr' ? 'https://seeklogo.com/images/F/fiverr-new-2020-logo-354E8A08FD-seeklogo.com.png' : 'https://datamenu.co.uk/wp-content/uploads/2024/06/dm-500-500.png'}
                                                alt={task.platform}
                                                className="platform-icon"
                                            />
                                        </div>
                                        <button className="contact-btn" onClick={() => handleContactClick(task.platform, task.contact)}>
                                            Contact
                                        </button>
                                        <div className={`task-deadline ${getDeadlineClass(new Date(task.deadline) - new Date())}`}>
                                            {formatDeadline(task.deadline)}
                                        </div>
                                    </div>
                                </div>
                                <button onClick={() => handleDeleteTask(task._id)} className="delete-btn">
                                    <i className="fa fa-trash"></i>
                                </button>
                            </div>
                        </div>
                    ))}
                </div>

                <div className="meeting-list">
                    <h2>Meetings</h2>
                    {meetings.map((meeting) => (
                        <div key={meeting._id} className="meeting-item">
                            <div className="meeting-card">
                                <div className="meeting-details">
                                    <h3>Meeting with {meeting.withWho}</h3>
                                    <div className="meeting-info">
                                        <div className="meeting-platform">
                                            <img
                                                src={meeting.platform === 'Fiverr' ? 'https://seeklogo.com/images/F/fiverr-new-2020-logo-354E8A08FD-seeklogo.com.png' : 'https://datamenu.co.uk/wp-content/uploads/2024/06/dm-500-500.png'}
                                                alt={meeting.platform}
                                                className="platform-icon"
                                            />
                                        </div>
                                        <div className="meeting-time">
                                            {new Date(meeting.meetingTime).toLocaleString()}
                                        </div>
                                    </div>
                                </div>
                                <button onClick={() => handleDeleteMeeting(meeting._id)} className="delete-btn">
                                    <i className="fa fa-trash"></i>
                                </button>
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            {descriptionModal.isOpen && (
                <div className="description-modal">
                    <div className="modal-content">
                        <span className="close" onClick={closeDescriptionModal}>&times;</span>
                        <p>{descriptionModal.content}</p>
                    </div>
                </div>
            )}

            {taskModal && (
                <div className="modal">
                    <div className="modal-content">
                        <span className="close" onClick={() => setTaskModal(false)}>&times;</span>
                        <form onSubmit={handleSubmitTask} className="task-form">
                            <h2>Add Task</h2>
                            <div className="form-group">
                                <label>Title</label>
                                <input
                                    type="text"
                                    value={taskForm.title}
                                    onChange={(e) => setTaskForm({ ...taskForm, title: e.target.value })}
                                    required
                                />
                            </div>
                            <div className="form-group">
                                <label>Description</label>
                                <input
                                    type="text"
                                    value={taskForm.description}
                                    onChange={(e) => setTaskForm({ ...taskForm, description: e.target.value })}
                                    required
                                />
                            </div>
                            <div className="form-group">
                                <label>Value</label>
                                <input
                                    type="number"
                                    value={taskForm.value}
                                    onChange={(e) => setTaskForm({ ...taskForm, value: e.target.value })}
                                    required
                                />
                            </div>
                            <div className="form-group">
                                <label>Platform</label>
                                <select value={taskForm.platform} onChange={(e) => setTaskForm({ ...taskForm, platform: e.target.value })}>
                                    <option value="Fiverr">Fiverr</option>
                                    <option value="Data Menu">Data Menu</option>
                                </select>
                            </div>
                            <div className="form-group">
                                <label>Contact</label>
                                <input
                                    type="text"
                                    value={taskForm.contact}
                                    onChange={(e) => setTaskForm({ ...taskForm, contact: e.target.value })}
                                    required
                                />
                            </div>
                            <div className="form-group">
                                <label>Deadline</label>
                                <input
                                    type="datetime-local"
                                    value={taskForm.deadline}
                                    onChange={(e) => setTaskForm({ ...taskForm, deadline: e.target.value })}
                                    required
                                />
                            </div>
                            <div className="button-group">
                                <button type="submit">Add Task</button>
                            </div>
                        </form>
                    </div>
                </div>
            )}

            {meetingModal && (
                <div className="modal">
                    <div className="modal-content">
                        <span className="close" onClick={() => setMeetingModal(false)}>&times;</span>
                        <form onSubmit={handleSubmitMeeting} className="meeting-form">
                            <h2>Add Meeting</h2>
                            <div className="form-group">
                                <label>Meeting Time</label>
                                <input
                                    type="datetime-local"
                                    value={meetingForm.meetingTime}
                                    onChange={(e) => setMeetingForm({ ...meetingForm, meetingTime: e.target.value })}
                                    required
                                />
                            </div>
                            <div className="form-group">
                                <label>With Who</label>
                                <input
                                    type="text"
                                    value={meetingForm.withWho}
                                    onChange={(e) => setMeetingForm({ ...meetingForm, withWho: e.target.value })}
                                    required
                                />
                            </div>
                            <div className="form-group">
                                <label>Platform</label>
                                <select value={meetingForm.platform} onChange={(e) => setMeetingForm({ ...meetingForm, platform: e.target.value })}>
                                    <option value="Fiverr">Fiverr</option>
                                    <option value="Data Menu">Data Menu</option>
                                </select>
                            </div>
                            <div className="button-group">
                                <button type="submit">Add Meeting</button>
                            </div>
                        </form>
                    </div>
                </div>
            )}
        </div>
    );

    function handleContactClick(platform, contact) {
        if (platform === 'Fiverr') {
            window.open(`https://www.fiverr.com/inbox/${contact}`, '_blank');
        } else {
            alert(contact);
        }
    }
};

export default TaskMeetingPage;

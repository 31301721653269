import React from 'react';
import { Link } from 'react-router-dom';
import './Featured.css';

import bbbImage from './tool-images/bbb.png';
import googleSearchImage from './tool-images/googlesearch.png';
import ypusaImage from './tool-images/yellowpagesusa.png';

const Featured = () => {
    return (
        <div id='sources' className='featured-container'>
            <Link to="/Google-Search">
                <div className='source-item'>
                    <div className='source-title'>
                        <span>Google Search</span>
                    </div>
                    <div className='source-image'>
                        <img src={googleSearchImage} alt="Google Search"></img>
                    </div>
                </div>
            </Link>
            <Link to="/BBB">
                <div className='source-item'>
                    <div className='source-title'>
                        <span>BBB</span>
                    </div>
                    <div className='source-image'>
                        <img src={bbbImage} alt="BBB"></img>
                    </div>
                </div>
            </Link>
            <Link to="/Yellow-Pages-USA">
                <div className='source-item'>
                    <div className='source-title'>
                        <span>Yellow Pages USA</span>
                    </div>
                    <div className='source-image'>
                        <img src={ypusaImage} alt="Yellow Pages USA"></img>
                    </div>
                </div>
            </Link>
        </div>
    );
};

export default Featured;

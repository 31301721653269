import React from 'react';
import './ToolPage.css';
import YellowPagesUSAForm from '../tool-components/YellowPagesUSAForm';
import logo from '../../components/tool-images/yellowpagesusa.png';
import Pricing from './components/Pricing';

const YellowPagesUSA = () => {
    return (
        <section className="tool-page-container">
            <div className="container">
                <div className="left-column">
                    <div className="image-row">
                        <img src={logo} alt="Yellow Pages USA Logo" className="image" />
                    </div>
                    <div className="form-row">
                        <YellowPagesUSAForm />
                    </div>
                </div>
                <div className="right-column">
                    <div className="content">
                        <Pricing rows={1} cost={10} title="Yellow Pages USA Search Pricing" />
                    </div>
                </div>
            </div>
            <div className='description-output'>
                <div className='description'>
                    <p>Our Yellow Pages USA tool helps you gather comprehensive business information from YellowPages.com, efficiently compiled into a neatly organized CSV file. Perfect for market researchers, business analysts, and anyone needing accurate, up-to-date business data.</p>
                </div>
                <h3>The Output</h3>
                <div className='output'>
                    <table>
                        <thead>
                            <tr>
                                <th>Title</th>
                                <th>Website</th>
                                <th>Phone</th>
                                <th>Email</th>
                                <th>Facebook</th>
                                <th>Address</th>
                                <th>Logo</th>
                                <th>Description</th>
                                <th>URL</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>The business title</td>
                                <td>The business website</td>
                                <td>The business phone number</td>
                                <td>The business email</td>
                                <td>The business Facebook page</td>
                                <td>The business address</td>
                                <td>The business logo URL</td>
                                <td>A brief description of the business</td>
                                <td>The URL to the business listing</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </section>
    );
};

export default YellowPagesUSA;

import React, { useState, useEffect } from 'react';

const Counter = ({ number, speed }) => {
  const [count, setCount] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      if (count < number) {
        setCount(prevCount => prevCount + 1);
      } else {
        clearInterval(interval);
      }
    }, (speed * 1000) / number);

    return () => clearInterval(interval);
  }, [count, number, speed]);

  return <span>{count}</span>;
};

export default Counter;

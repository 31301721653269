import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import './Register.css';

const Register = () => {
    const { login } = useAuth();
    const navigate = useNavigate();

    const [credentials, setCredentials] = useState({
        username: '',
        email: '',
        password: '',
        twoFactorCode: '',
        firstName: '',
        lastName: '',
        streetAddress: '',
        city: '',
        state: '',
        postalCode: '',
        country: 'UK',
        gender: 'not specified',
        phone: '',
        website: ''
    });

    const [verify, setVerify] = useState({
        userID: '',
        qrCode: '',
        verifyCode: ''
    });

    const [errors, setErrors] = useState([]);
    const [loginPhase, setLoginPhase] = useState('initial');
    const [error, setError] = useState('');

    const handleInputChange = (event) => {
        setCredentials({
            ...credentials,
            [event.target.name]: event.target.value
        });
    };

    const handleInputChange2FA = (event) => {
        setVerify({
            ...verify,
            [event.target.name]: event.target.value
        });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setError('');

        var endpoint = 'https://api.datamenu.co.uk/register';

        const response = await fetch(endpoint, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(credentials)
        });

        const data = await response.json();

        var errorList = [];

        if (response) {
            if (data.error) {
                if (data.error._message === "User validation failed") {
                    var errors = data.error.errors;
                    var errorKeys = Object.keys(errors);
                    for (let e of errorKeys) {
                        var EM = errors[e];
                        var errorMessage = EM.message;
                        var cleanedMessage = errorMessage.replace('Path `', '').replace(/`/g, '').replace('email', 'Email').replace('phone', 'Phone Number').replace('website', 'Website').replace('username', 'Username').replace('password', 'Password').replace('firstName', 'First Name').replace('lastName', 'Last Name').replace('gender', 'Gender').replace('streetAddress', 'Street Address').replace('city', 'City').replace('state', 'State/County/Province').replace('postalCode', 'Postal Code/ZIP').replace('country', 'Country').replace('(', '("').replace(')', '")');
                        errorList.push(cleanedMessage);
                    }

                    setErrors(errorList);
                    setLoginPhase('initial-invalid');
                }
            }
            if (data.message === 'User registered, verify 2FA to activate account') {
                setVerify({
                    userID: data.userId,
                    qrCode: data.qrCodeUrl
                });
                setLoginPhase('2FA');
            } else if (data.message === 'user already exists') {
                setLoginPhase('initial-alreadyFound');
            } else if (data.message === '2FA token is invalid') {
                setLoginPhase('2FA-Invalid');
            }
        } else {
            setError(data.error || 'An error occurred');
        }
    };

    const handleSubmit2FA = async (event) => {
        event.preventDefault();
        setError('');

        var endpoint = 'https://api.datamenu.co.uk/verify-2fa';
        console.log(verify);

        var verifyCreds = {
            userId: verify.userID,
            token: verify.verifyCode,
        };

        const response = await fetch(endpoint, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(verifyCreds)
        });

        const data = await response.json();

        if (response) {
            if (data.message === 'User registered, verify 2FA to activate account') {
                setVerify({
                    userID: data.userId,
                    qrCode: data.qrCodeUrl
                });
                setLoginPhase('2FA');
            } else if (data.message === 'user already exists') {
                setLoginPhase('initial-alreadyFound');
            } else if (data.message === 'email already exists') {
                setLoginPhase('initial-emailAlready');
            } else if (data.message === 'phone already exists') {
                setLoginPhase('initial-phoneAlready');
            } else if (data.message === '2FA token is invalid') {
                setLoginPhase('2FA-Invalid');
            } else if (data.message === '2FA is verified and enabled') {
                login(data.session_token, data.user_id);
                navigate('/dashboard');
            }
        } else {
            setError(data.error || 'An error occurred');
        }
    };

    return (
        <div className="register-container">
            {loginPhase.includes('2FA') && (
                <form className='login-form' onSubmit={handleSubmit2FA}>
                    <div className='verify-info'>
                        <h2>2FA</h2>
                        <p>Please scan the following QR code with your authentication application. This can be Microsoft Authenticate, or Google Authenticator.</p>
                    </div>
                    <img src={verify.qrCode} alt="QR Code"></img>
                    <label>
                        Two Factor Code:
                        <input
                            type="text"
                            name="verifyCode"
                            value={verify.verifyCode}
                            onChange={handleInputChange2FA}
                            className="input-field"
                        />
                    </label>
                    <button type="submit" className="login-button">Login</button>
                    {loginPhase.includes('Invalid') && (
                        <p className="error-message">2FA Token Invalid - Please Try Again</p>
                    )}
                </form>
            )}
            {!loginPhase.includes('2FA') && (
                <form className='login-form' onSubmit={handleSubmit}>
                    {loginPhase.includes('initial') && (
                        <>
                            <label>
                                Email:
                                <input
                                    type="text"
                                    name="email"
                                    value={credentials.email}
                                    onChange={handleInputChange}
                                    className="input-field"
                                />
                            </label>
                            <label>
                                Phone Number:
                                <input
                                    type="text"
                                    name="phone"
                                    value={credentials.phone}
                                    onChange={handleInputChange}
                                    className="input-field"
                                />
                            </label>
                            <label>
                                Website:
                                <input
                                    type="text"
                                    name="website"
                                    value={credentials.website}
                                    onChange={handleInputChange}
                                    className="input-field"
                                />
                            </label>
                            <label>
                                Username:
                                <input
                                    type="text"
                                    name="username"
                                    value={credentials.username}
                                    onChange={handleInputChange}
                                    className="input-field"
                                />
                            </label>
                            <label>
                                Password:
                                <input
                                    type="password"
                                    name="password"
                                    value={credentials.password}
                                    onChange={handleInputChange}
                                    className="input-field"
                                />
                            </label>
                            <hr />
                            <label>
                                First Name:
                                <input
                                    required
                                    type="text"
                                    name="firstName"
                                    value={credentials.firstName}
                                    onChange={handleInputChange}
                                    className="input-field"
                                />
                            </label>
                            <label>
                                Last Name:
                                <input
                                    required
                                    type="text"
                                    name="lastName"
                                    value={credentials.lastName}
                                    onChange={handleInputChange}
                                    className="input-field"
                                />
                            </label>
                            <label>
                                Gender:
                                <select
                                    required
                                    name="gender"
                                    value={credentials.gender}
                                    onChange={handleInputChange}
                                    className="input-field"
                                >
                                    <option selected disabled>Gender</option>
                                    <option value="male">Male</option>
                                    <option value="female">Female</option>
                                    <option value="other">Other</option>
                                    <option value="not specified">Prefer Not To Say</option>
                                </select>
                            </label>
                            <hr />
                            <label>
                                Street Address:
                                <input
                                    required
                                    type="text"
                                    name="streetAddress"
                                    value={credentials.streetAddress}
                                    onChange={handleInputChange}
                                    className="input-field"
                                />
                            </label>
                            <label>
                                City:
                                <input
                                    required
                                    type="text"
                                    name="city"
                                    value={credentials.city}
                                    onChange={handleInputChange}
                                    className="input-field"
                                />
                            </label>
                            <label>
                                State/County/Province:
                                <input
                                    required
                                    type="text"
                                    name="state"
                                    value={credentials.state}
                                    onChange={handleInputChange}
                                    className="input-field"
                                />
                            </label>
                            <label>
                                Postal Code/ZIP:
                                <input
                                    required
                                    type="text"
                                    name="postalCode"
                                    value={credentials.postalCode}
                                    onChange={handleInputChange}
                                    className="input-field"
                                />
                            </label>
                            <label>
                                Country:
                                <select
                                    required
                                    name="country"
                                    value={credentials.country}
                                    onChange={handleInputChange}
                                    className="input-field"
                                >
                                    <option selected disabled>Country</option>
                                    <option value="UK">UK</option>
                                    <option value="US">US</option>
                                    <option value="CA">CA</option>
                                    <option value="AU">AU</option>
                                    <option value="NZ">NZ</option>
                                    <option value="IN">IN</option>
                                    <option value="FR">FR</option>
                                    <option value="IT">IT</option>
                                    <option value="GE">GE</option>
                                </select>
                            </label>
                            {loginPhase.includes('initial-alreadyFound') && (
                                <p className="error-message">Account already exists... <a href="/login" className="link">Login here</a></p>
                            )}
                            {loginPhase.includes('initial-emailAlready') && (
                                <p className="error-message">Email already in use... <a href="/login" className="link">Login here</a></p>
                            )}
                            {loginPhase.includes('initial-phoneAlready') && (
                                <p className="error-message">Phone already in use... <a href="/login" className="link">Login here</a></p>
                            )}
                            {loginPhase.includes('invalid') && (
                                <div className="error-container">
                                    {errors.map((error, index) => (
                                        <p key={index} className="error-message">{error}</p>
                                    ))}
                                </div>
                            )}
                        </>
                    )}
                    <button type="submit" className="login-button">Sign Up</button>
                </form>
            )}
            <p>Already with us? <a href="/login" className="link">Login here</a></p>
            {error && <p className="error-message">{error}</p>}
        </div>
    );
};

export default Register;

import React, { useState, useEffect } from 'react';
import { useAuth } from '../context/AuthContext';
import Sidebar from '../components/Sidebar';
import GeneralInfo from '../components/GeneralInfo';
import AccountSettings from '../components/AccountSettings';
import './MyAccount.css';

const MyAccount = () => {
    const { logout } = useAuth();
    const [userId, setUserId] = useState('');
    const [activeTab, setActiveTab] = useState('General');
    const [userData, setUserData] = useState({
        username: '',
        email: '',
        phone: '',
        website: '',
        streetAddress: '',
        city: '',
        state: '',
        country: '',
        postalCode: '',
        gender: '',
        firstName: '',
        lastName: ''
    });

    useEffect(() => {
        const fetchUserData = async (storedUserId) => {
            try {
                const response = await fetch('https://api.datamenu.co.uk/getUser', {
                    method: 'POST',
                    body: { storedUserId }
                });
                const userDataRes = await response.json();
                setUserData(userDataRes.data);
            } catch (error) {
                console.error('Error fetching user data:', error);
            }
        };

        const storedUserId = localStorage.getItem('userId');
        if (storedUserId) {
            setUserId(storedUserId);
            fetchUserData(storedUserId);
        }
    }, []);

    return (
        <div className="my-account-container">
            <Sidebar setActiveTab={setActiveTab} />
            <div className="content">
                {activeTab === 'General' && <GeneralInfo userData={userData} />}
                {activeTab === 'Account Settings' && <AccountSettings userId={userId} logout={logout} />}
            </div>
        </div>
    );
};

export default MyAccount;

import React, { useState } from 'react';
import './TelegramMessages.css';

const TelegramMessages = () => {
    const [message, setMessage] = useState('');

    const handleSendMessage = async () => {
        try {
            const response = await fetch('https://api.datamenu.co.uk/send-tg-message', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ message }),
            });
            const result = await response.json();
            console.log(result);
            if (result.success) {
                alert('Message sent successfully!');
            } else {
                alert('Failed to send message');
            }
        } catch (error) {
            console.error('Error sending message:', error);
            alert('Error sending message');
        }
    };

    const handlePresetMessage = (presetMessage) => {
        setMessage(presetMessage);
    };

    return (
        <div className="dashboard-container">
            <h1>Send Message to Telegram Channels</h1>
            <textarea
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                placeholder="Type your message here..."
                rows="4"
                cols="50"
            />
            <br />
            <button onClick={handleSendMessage}>Send</button>
            <br />
            <button onClick={() => handlePresetMessage("🚀 Hey, I'm updating in a few moments, please hold tight and re-track your tokens once I am back up and running!")}>UPDATE</button>
            <button onClick={() => handlePresetMessage("🎉 We're back baby! - Let's track some tokens!")}>FINISHED UPDATING</button>
            <button onClick={() => handlePresetMessage("🙏 If you would like to support the development of the project, please use /tip for more information.")}>SUPPORT</button>
        </div>
    );
};

export default TelegramMessages;

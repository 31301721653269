import React from 'react';
import { Link } from 'react-router-dom';
import './Categories.css';
import Category from './Category';

const Categories = () => {
    return (
        <div id='categories' className='categories-container'>
            <Link to={'/directories'}><Category title={"Directories"} icon={"fa fa-compass"} /></Link>
            <Link to={'/ecommerce'}><Category title={"E-Commerce"} icon={"fa fa-cash-register"} /></Link>
            <Link to={'/real-estate'}><Category title={"Real Estate"} icon={"fa fa-sign-hanging"} /></Link>
            <Link to={'/search-engines'}><Category title={"Search Engines"} icon={"fa fa-magnifying-glass-location"} /></Link>
            <Link to={'/hotels-and-accommodation'}><Category title={"Hotels & Accommodation"} icon={"fa fa-bell-concierge"} /></Link>
            <Link to={'/sports'}><Category title={"Sports"} icon={"fa fa-futbol"} /></Link>
            <Link to={'/business-reviews'}><Category title={"Business Reviews"} icon={"fa fa-star-sharp"} /></Link>
        </div>
    );
};

export default Categories;

import React from 'react';
import './About.css';
import ContactForm from '../components/ContactForm';

const About = () => {
    return (
        <div className="about-container">
            <header className="about-header">
                <h1>About Data Menu</h1>
                <p>Welcome to Data Menu, your ultimate data management platform.</p>
            </header>

            <section className="mission-vision">
                <div className="mission">
                    <h2>Our Mission</h2>
                    <p>At Data Menu, our mission is to revolutionize the way businesses access and utilize data. We strive to provide a user-friendly platform where clients can obtain data directly from sources without the exorbitant costs associated with traditional data brokers. By enabling businesses to collect data efficiently, we help them save time and resources, ultimately driving growth and innovation.</p>
                </div>
                <div className="vision">
                    <h2>Our Vision</h2>
                    <p>Our vision is to grow alongside our clients, continually enhancing our platform based on their feedback. We aim to provide the most up-to-date data and introduce new SaaS tools tailored to client needs. Our goal is to create an all-in-one platform where clients can choose flexible plans or pay-as-you-go options, avoiding the financial burden of traditional data solutions.</p>
                </div>
            </section>

            <section className="history">
                <h2>Our History</h2>
                <p>Founded in November 2022, Data Menu Limited made its first competitive sale on launch day. Since then, we have helped over 500 businesses streamline their workflows, automate systems, and integrate multiple systems into our platform. Our commitment to innovation has driven our growth and success in the industry.</p>
            </section>

            <section className="services">
                <h2>Our Services</h2>
                <ul>
                    <li>Custom Solutions: Automating platforms or software to meet unique client needs.</li>
                    <li>Data Collection: Providing requested data upon payment.</li>
                    <li>SaaS: The platform you are currently using, offering numerous data sources with thousands more in development.</li>
                    <li>Databank Solutions: Subscription-based access to datasets.</li>
                    <li>Sports and Betting Platform: Providing insights into current sports events to aid in decision-making.</li>
                </ul>
            </section>

            <section className="suggestions">
                <h2>Have a Suggestion?</h2>
                <p>We encourage our clients to suggest new sources or features they would like to see. Use the form below to submit your suggestions:</p>
                <ContactForm />
            </section>
        </div>
    );
};

export default About;

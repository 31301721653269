import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './ContactForm.css';

function ContactForm() {
    const [emailData, setEmail] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        message: '',
        consentContact: '',
        consentThirdPartyUsage: ''
    });

    const [emailPhase, setEmailPhase] = useState('initial');

    const handleSubmit = async (event) => {
        event.preventDefault();

        var endpoint = 'https://api.datamenu.co.uk/send-message';

        const response = await fetch(endpoint, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(emailData)
        });

        const data = await response.json()

        if (response) {
            console.log(data);
            var result = data.result;
            if (result === 'success') {
                setEmailPhase('sent');
            } else {
                setEmail('initial-error');
            }
        }
    };

    const handleInputChange = (event) => {
        setEmail({
            ...emailData,
            [event.target.name]: event.target.value
        });
    };

    return (
        <div className="contact-form-container">
            {emailPhase.includes('initial') && (
                <form className='contact-form' onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label>
                            <input
                                placeholder='First Name'
                                type="text"
                                name="firstName"
                                value={emailData.firstName}
                                onChange={handleInputChange}
                                required
                            />
                        </label>
                        <label>
                            <input
                                placeholder='Last Name'
                                type="text"
                                name="lastName"
                                value={emailData.lastName}
                                onChange={handleInputChange}
                                required
                            />
                        </label>
                    </div>
                    <div className="form-group">
                        <label>
                            <input
                                placeholder='Email Address'
                                type="email"
                                name="email"
                                value={emailData.email}
                                onChange={handleInputChange}
                                required
                            />
                        </label>
                        <label>
                            <input
                                placeholder='Phone Number'
                                type="tel"
                                name="phoneNumber"
                                value={emailData.phoneNumber}
                                onChange={handleInputChange}
                            />
                        </label>
                    </div>
                    <label className="form-group">
                        <textarea
                            placeholder='Write your message here...'
                            name="message"
                            value={emailData.message}
                            onChange={handleInputChange}
                            required
                        />
                    </label>
                    <hr />
                    <label className='consent'>
                        <input
                            type="checkbox"
                            name="consentContact"
                            value={emailData.consentContact}
                            onChange={handleInputChange}
                            required
                        />
                        Do you consent to being contacted by Data Menu Limited and its employees?
                    </label>
                    <label className='consent'>
                        <input
                            type="checkbox"
                            name="consentThirdPartyUsage"
                            value={emailData.consentThirdPartyUsage}
                            onChange={handleInputChange}
                            required
                        />
                        By checking this box, you confirm that you have read and agree to our <Link to={'/privacy-policy'}>privacy policy</Link>, and you consent to the use of your data by third-party vendors. They may contact you if your activity on our site is deemed beneficial and of interest to both parties.
                    </label>
                    <button type="submit" className="submit-button">Send Message</button>
                    {emailPhase.includes('error') && (
                        <p className='error-message'>Error sending message, please try again later</p>
                    )}
                </form>
            )}

            {emailPhase === 'sent' && (
                <div className='message-sent'>
                    <h3>Message Sent</h3>
                    <p>Please allow up to 48 hours for a response.</p>
                </div>
            )}
        </div>
    );
}

export default ContactForm;

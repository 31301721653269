import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './Header.css';
import { useAuth } from '../context/AuthContext';
import { Helmet } from 'react-helmet';
import logo from '../assets/logo.png';
import dmIcon from '../assets/dm-500-500.png';
import AutomatedBrowserCheck from './AutomatedBrowserCheck';

const Header = () => {
    const { isAuthenticated, userCredit, userRole, logout } = useAuth();
    const location = useLocation();
    const [menuOpen, setMenuOpen] = useState(false);

    const isActive = (path) => location.pathname === path ? 'active' : '';

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    return (
        <header className="header">
            <AutomatedBrowserCheck />
            <Helmet>
                <script src="https://kit.fontawesome.com/81b7a177ab.js" crossOrigin="anonymous"></script>
            </Helmet>
            <div className="header-top">
                <div className="logo">
                    <Link to="/">
                        <img src={logo} alt="Data Menu Logo" />
                    </Link>
                    <h1>DATA MENU</h1>
                </div>
                {isAuthenticated && (
                    <div className="user-info">
                        <span className="credit-box">
                            <img src={dmIcon} className="credit-image" alt="Credit Icon" />
                            {userCredit.toLocaleString()}
                        </span>
                        <span className="user-role">{userRole}</span>
                    </div>
                )}
                <button className="burger-menu" onClick={toggleMenu}>
                    <i className="fa fa-bars"></i>
                </button>
            </div>
            <nav className={`header-nav ${menuOpen ? 'open' : ''}`}>
                <ul className="nav-list">
                    <li className={isActive('/') ? 'active' : ''}><Link to="/" onClick={toggleMenu}>Home</Link></li>
                    <li className="dropdown">
                        <span className="dropdown-toggle">Business</span>
                        <ul className="dropdown-menu">
                            <li className={isActive('/about') ? 'active' : ''}><Link to="/about" onClick={toggleMenu}>About</Link></li>
                            <li className={isActive('/contact') ? 'active' : ''}><Link to="/contact" onClick={toggleMenu}>Contact</Link></li>
                        </ul>
                    </li>
                    <li className="dropdown">
                        <span className="dropdown-toggle">Services</span>
                        <ul className="dropdown-menu">
                            <li className={isActive('/free-tools') ? 'active' : ''}><Link to="/free-tools" onClick={toggleMenu}>Free Tools</Link></li>
                            <li className={isActive('/premium-tools') ? 'active' : ''}><Link to="/premium-tools" onClick={toggleMenu}>Premium SaaS</Link></li>
                            <li className={isActive('/custom-solutions') ? 'active' : ''}><Link to="/custom-solutions" onClick={toggleMenu}>Custom Solutions</Link></li>
                            <li className={isActive('/data-collection') ? 'active' : ''}><Link to="/data-collection" onClick={toggleMenu}>Data Collection</Link></li>
                        </ul>
                    </li>
                    {isAuthenticated ? (
                        <>
                            {userRole === "free" && <li className={isActive('/pricing') ? 'active' : ''}><Link to="/pricing" onClick={toggleMenu}>Pricing</Link></li>}
                            <li className={isActive('/dashboard') ? 'active' : ''}><Link to="/dashboard" onClick={toggleMenu}>Dashboard</Link></li>
                            <li className="dropdown">
                                <span className="dropdown-toggle">Account</span>
                                <ul className="dropdown-menu">
                                    <li className={isActive('/my-account') ? 'active' : ''}><Link to="/my-account" onClick={toggleMenu}>My Account</Link></li>
                                    <li className={isActive('/purchase-credits') ? 'active' : ''}><Link to="/purchase-credits" onClick={toggleMenu}>Top-up Credits</Link></li>
                                    {userRole !== "free" && <li><a href="https://billing.stripe.com/p/login/test_6oE2aA0qI123aw8bII">Manage Subscription</a></li>}
                                    <li onClick={logout}><Link to="#">Logout</Link></li>
                                </ul>
                            </li>
                        </>
                    ) : (
                        <>
                            <li className={isActive('/pricing') ? 'active' : ''}><Link to="/pricing" onClick={toggleMenu}>Pricing</Link></li>
                            <li className={isActive('/login') ? 'active' : ''}><Link to="/login" onClick={toggleMenu}>Login</Link></li>
                        </>
                    )}
                </ul>
            </nav>
        </header>
    );
};

export default Header;

import React from 'react';

const Services = () => {
    return (
        <div style={{ textAlign: 'center', marginTop: '50px' }}>
            <h1>Welcome to Data Menu</h1>
            <p>Your ultimate data management platform.</p>
            <p>
                Explore our tools and services to enhance your data-driven decisions.
                Navigate through the menu to find all the services we offer.
            </p>
        </div>
    );
};

export default Services;
